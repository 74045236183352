import React, { ChangeEvent, PropsWithChildren, useEffect, useState } from "react"
import _ from "lodash"
import { Address, AmountInfo, CartEntry, PageInfo, SelectStickersItem, ShopItem } from "../types"
import { useECommerce } from "../context"
import I18n from "i18n-js"
import MainAppSectionTitle from "@root/components/SectionTitle"
import Button from "@root/components/Button"
import { SelectStickersMode } from "../views/ViewSelectStickers"

export const Row: React.FC<PropsWithChildren> = ({children}) => <div className="neo__ec__row">{children}</div>

export const Title: React.FC<PropsWithChildren> = ({children}) => <MainAppSectionTitle>{children}</MainAppSectionTitle>

export const SectionTitle: React.FC<PropsWithChildren> = ({children}) => <h3 className="neo__ec__sectionTitle"><span><span>{children}</span></span></h3>

export const Info: React.FC<PropsWithChildren> = ({children}) => <Panel size="slim" style="transparent"><div className="neo__ec__info">{children}</div></Panel>

export const ActionsHolder: React.FC<PropsWithChildren> = ({children}) => <div className="neo__ec__actionsHolder">{children}</div>

export const ActionLinkHolder: React.FC<PropsWithChildren> = ({children}) => <div className="neo__ec__actionLinkHolder">{children}</div>

export const ItemList: React.FC<PropsWithChildren> = ({children}) => <div className="neo__ec__itemList">{children}</div>

export const ItemListEmptyNote: React.FC<PropsWithChildren> = ({children}) => <div className="neo__ec__itemList__emptyNote">{children}</div>

export const ItemListSection: React.FC<PropsWithChildren> = ({children}) => <div className="neo__ec__itemList__sectionHolder"><SectionTitle>{children}</SectionTitle></div>

export const PageWrapper: React.FC<PropsWithChildren<{variant?: string}>> = ({children, variant}) => <div className={`neo__ec__pageWrapper${variant ? ` neo__ec__pageWrapper--${variant}` : ""}`}>{children}</div>

export const SelectStickersWrapper: React.FC<PropsWithChildren<{slim?: Boolean}>> = ({children, slim}) => <div className={`neo__ec__selectStickersWrapper${slim ? " neo__ec__selectStickersWrapper--slim" : ""}`}>{children}</div>

export const ButtonSubinfo: React.FC<PropsWithChildren> = ({children}) => <div className="neo__ec__buttonSubinfo">{children}</div>

export const StickerToSelect: React.FC<{mode: SelectStickersMode, sticker: SelectStickersItem, blockedForSelection: boolean, amountAlreadySelected: number, doInc(): void, doDec(): void}> = ({sticker, amountAlreadySelected, doDec, doInc, blockedForSelection, mode}) => {
  const className = [
    "neo__ec__sticker",
    blockedForSelection ? "neo__ec__sticker--blockedForSelection" : "neo__ec__sticker--notBlockedForSelection",
    amountAlreadySelected > 0 ? "neo__ec__sticker--selected" : "neo__ec__sticker--notSelected",
  ].join(" ")

  return <div className={className}>
    <div className="neo__ec__sticker__inner">
      <h3><span>{sticker.name}</span><strong>{sticker.number}</strong></h3>
      <div className="neo__ec__sticker__img" style={{backgroundImage: `url(${sticker.image})`}} />
      {blockedForSelection
        ? <div className="neo__ec__sticker__pickPlaceholder" />
        : (amountAlreadySelected === 0
          ? <div className="neo__ec__sticker__pick">
              <div>
                <a onClick={() => doInc()}>+</a>
              </div>
            </div>
          : <>
              {mode === "ALLOW_MULTIPLE"
                ? <div className="neo__ec__sticker__pick">
                    <div>
                      <a onClick={() => doDec()}>-</a>
                      <strong>{amountAlreadySelected}×</strong>
                      <a onClick={() => doInc()}>+</a>
                    </div>
                  </div>
                : <>
                    <div className="neo__ec__sticker__pick__singleUnselectHolder"><a onClick={() => doDec()}>{I18n.t("ecommerce.select_stickers.action_unselect_sticker")}</a></div>
                    <div className="neo__ec__sticker__pick">
                      <div className="neo__ec__sticker__pick__singleWrapper">
                        <a onClick={() => doDec()} />
                      </div>
                    </div>
                  </>}
            </>)}
    </div>
  </div>
}

export const StickerToShow: React.FC<{sticker: SelectStickersItem, amount?: number}> = ({sticker, amount}) => {
  amount || (amount = 1)

  const className = [
    "neo__ec__sticker",
    "neo__ec__sticker--notSelected",
  ].join(" ")

  return <div className={className}>
    <div className="neo__ec__sticker__inner">
      <h3><span>{sticker.name}</span><strong>{sticker.number}</strong></h3>
      <div className="neo__ec__sticker__img" style={{backgroundImage: `url(${sticker.image})`}} />
        <div className="neo__ec__sticker__pick">
          <div>
            <strong>{amount}×</strong>
          </div>
        </div>
    </div>
  </div>
}

export const FormRow: React.FC<PropsWithChildren<{tinyMargin?: boolean}>> = ({children, tinyMargin}) => {
  return <div className={`neo__ec__formRow neo__ec__formRow--${tinyMargin ? "tinyMargin" : "default"}`}>{children}</div>
}

export const FormHolder: React.FC<PropsWithChildren> = ({children}) => {
  return <div className="neo__ec__formHolder">{children}</div>
}

export const FormLabelAndInput: React.FC<PropsWithChildren<{label: string, hasError?: boolean}>> = ({children, label, hasError}) => {
  return <div className={`neo__ec__addressForm__labelAndInput neo__ec__addressForm__labelAndInput--${hasError ? "withError" : "withoutError"}`}>
    <label>
      <span>{label}</span>
      <div>{children}</div>
    </label>
  </div>
}

export const Panel: React.FC<PropsWithChildren<{style: "transparent" | "box", size: "slim" | "full", fullContent?: boolean}>> = ({children, style, size, fullContent}) => {
  const className = [
    "neo__ec__panel",
    `neo__ec__panel--${fullContent ? "fullContent" : "paddedContent"}`,
    `neo__ec__panel--${style}`,
    `neo__ec__panel--${size}`,
  ].join(" ")
  return <div className={className}>{children}</div>
}

export const PriceValue: React.FC<{value: string}> = ({value}) => <span className="neo__ec__priceValue">{value}</span> 

export const babelPropsForExternalLinks = (links: {[varName: string]: {label: string, href: string}}) => {
  const props: {[key: string]: string} = {}
  _.each(_.keys(links), (k) => {
    props[k] = `<a href='${links[k].href}' target='_blank' rel='external'>${links[k].label}</a>`
  })
  return props
}

export const ShopItemView: React.FC<PropsWithChildren<{hidePrice?: boolean, item: ShopItem, actionLabel?: string, action?(): void}>> = ({item, actionLabel, action, children, hidePrice}) => {
  return <>
    <div className="neo__ec__shopItem">
      <div className={`neo__ec__shopItem__imgHolder neo__ec__shopItem__imgHolder--${(item.out_of_stock || item.coming_soon) ? "unavailable" : "available"}`}>
        {item.image && <img src={item.image.thumb} />}
      </div>
      <div className="neo__ec__shopItem__textHolder">
        <h3>{item.name || item.description}</h3>
        {!hidePrice && <p>
          <PriceValue value={item.price.value} />
          {" "}
          {item.price.subtitle}
        </p>}
        {item.description && item.name && <p className="neo__ec__shopItem__desc">{item.description}</p>}
        {item.out_of_stock &&
          <p><span className="neo__ec__shopItem__outOfStock">{I18n.t("ecommerce.available_items.out_of_stock")}</span></p>}
        {item.coming_soon &&
          <p><span className="neo__ec__shopItem__comingSoon"><span>{I18n.t("ecommerce.available_items.coming_soon")}</span></span></p>}
        {action && actionLabel &&
          <p>
            <Button onClick={() => action()}>{actionLabel}</Button>
          </p>}
      </div>
    </div>
    {children && <div className="neo__ec__shopItem__below">{children}</div>}
  </>
}

export const ShortAddress: React.FC<{address: Address, actionArea?: React.ReactNode}> = ({address, actionArea}) => {
  return <>
    <div className="neo__ec__shortAddress">
      <div>{`${address.lastname}, ${address.firstname}`}</div>
      <div>{address.country?.name}</div>
      <div>{`${address.city}, ${address.postcode}`}</div>
      <div>{address.street.join(", ")}</div>
    </div>
    {actionArea && <div className="neo__ec__shortAddress__below">
      {actionArea}</div>}
  </>
}

export const AmountChanger: React.FC<{item: ShopItem, amountInfo: AmountInfo}> = ({item, amountInfo}) => {
  const {dispatch} = useECommerce()
  const [amount, setAmount] = useState(amountInfo.amount)

  useEffect(() => {
    setAmount(amountInfo.amount)

    return () => { } // noop
  }, [amountInfo.amount])

  const amountInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    try {
      setAmount(parseInt(evt.target.value))
    } catch(e) {}
  }

  const sendChangedAmountToServer = () => {
    try {
      dispatch({type: "ecommerce__changeItemAmountInCart", data: {amount: amount, item}})
    } catch(e) {}
  }

  return <div className="neo__ec__amountInfo__amountInputHolder">
    <div>
      {I18n.t("ecommerce.cart.label_change_amount")}
      {" "}
      <input className="neo__ec__textInput" type="number" min={1} max={_.isNumber(amountInfo.max) ? amountInfo.max : undefined} value={amount} onChange={amountInputChange} />
    </div>
    <div>
      <a onClick={sendChangedAmountToServer}>{I18n.t("ecommerce.cart.action_change_amount")}</a>
    </div>
  </div>
}

export const AmountInfoView: React.FC<{selectedStickers?: CartEntry["selected_stickers"], hidePrice?: boolean, item: ShopItem, amountInfo: AmountInfo, withChanger?: boolean}> = ({item, amountInfo, withChanger, hidePrice, selectedStickers}) => {
  return <div className="neo__ec__amountInfo">
    <h4>
      {item.configuration_type === "select_stickers"
        ? I18n.t("ecommerce.cart.label_num_stickers")
        : I18n.t("ecommerce.cart.label_copies")}
    </h4>
    <div className="neo__ec__amountInfo__amountAndPrice">
      <div>
          {amountInfo.amount}×
      </div>
      <div className="neo__ec__amountInfo__priceHolder">
        {!hidePrice && !(`${item.price.value}${item.price.subtitle || ""}`.length === 0) && <>
          <PriceValue value={amountInfo.total_price.value} />
          {" "}
          {amountInfo.total_price.subtitle}
        </>}
      </div>
    </div>
    {withChanger && amountInfo.can_change &&
      <AmountChanger item={item} amountInfo={amountInfo}/>}
    {selectedStickers && <>
      <div className="neo__ec__amountInfo__stickersList">
        {_.map(selectedStickers, (st, index) => {
          return <div key={index}>{st.amount && st.amount > 1 ? `${st.amount}× ` : ""}{st.sticker.name}{` (${st.sticker.number})`}</div>
        })}
      </div>
    </>}
  </div>
}

export const SupportedCountries: React.FC<{countries: string[]}> = ({countries}) => {
  return <div className="neo__ec__supportedCountries">
    {_.map(countries, (c) => {
      return <>{c}</>
    })}
  </div>
}

export const Pagination: React.FC<{pageInfo: PageInfo, onChange(page: number): void}> = ({pageInfo, onChange}) => {
  return <div className="neo__ec__pagination">
    {_.times(pageInfo.total_pages, (i) => {
      const page = i + 1
      return <a key={i} className={page === pageInfo.page ? "neo__ec__pagination--current" : "neo__ec__pagination--notCurrent"} onClick={() => onChange(page)}>{page}</a>
    })}
  </div>
}

export const OrderLabelAndValue: React.FC<{label: string, value: any}> = ({label, value}) => {
  return <div className="neo__ec__order__labelAndValue">
    <SectionTitle>{label}</SectionTitle>
    <div className="neo__ec__order__val">{value}</div>
  </div>
}

export const OrderInlineLabelAndValue: React.FC<{label: string, value: any}> = ({label, value}) => {
  return <div className="neo__ec__order__inlineLabelAndValue">
    <div>{label}</div>
    <div className="neo__ec__order__val">{value}</div>
  </div>
}

export const ConfirmAction: React.FC<{
  actionLabel: string
  action(): void
  confirmQuestion: string
  confirmYes: string
  confirmNo: string
}> = ({action, actionLabel, confirmNo, confirmQuestion, confirmYes}) => {
  const [shallConfirm, setShallConfirmAbort] = useState(false)
  return <div className="neo__ec__confirmAction">
    {shallConfirm
      ? <div className="neo__ec__confirmAction__askingHolder">
          <div className="neo__ec__confirmAction__confirmQuestion">{confirmQuestion}</div>
          <div className="neo__ec__confirmAction__confirmOptions">
            <a onClick={() => action()}>{confirmYes}</a>
            <a onClick={() => setShallConfirmAbort(false)}>{confirmNo}</a>
          </div>  
        </div>
      : <div className="neo__ec__confirmAction__actionHolder">
          <a onClick={() => setShallConfirmAbort(true)} className="neo__ec__confirmAction__actionLink">{actionLabel}</a>
        </div>}
  </div>
}

export const RestrictedToCountries: React.FC<{countries: string[]}> = ({countries}) => {
  return <Panel size="slim" style="transparent">
    <SectionTitle>{I18n.t("ecommerce.country_restriction_label")}</SectionTitle>
    <div className="neo__ec__restrictedToCountriesList">
      {countries.join(", ")}
    </div>
  </Panel>
}

export const LoadingPlaceholder: React.FC = () => <></>
