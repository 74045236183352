import React, { PropsWithChildren, useEffect, useState } from "react"

import SectionTitle from "@root/components/SectionTitle"
import I18n from "i18n-js"
import _ from "lodash"
import Button from "@root/components/Button"
import axios, { AxiosResponse } from "axios"

import coinImg from "@images/enterCode__rewardCoins.png"
import packsInfoImg from "@images/enterCode__packsOpenInfoBg.png"
import LogoutButton, { LogoutButtonHolder } from "@root/components/LogoutButton"

const b = (key: string) => `web.pages.enter_code.redeem.${key}`

interface StateBase {
  code?: string
  collection?: number
}
interface StateError extends StateBase {
  error?: string
}
interface StateSuccess extends StateBase {
  effect: {
    coins?: {
      gained: number
      total_now: number
    },
    packs?: {
      amount: number
      name: string
      pack_img: string
      cover_img: string
    },
    stickers?: {
      granted: {
        img: string
        name: string
        number: number // number in collection
        already_owned?: boolean
      }[]
    }
  }
}
interface StateRequiresCollection extends StateBase {
  requires_collection: {
    candidates: [{
      id: number
      name: string
      pack_img: string
      cover_img: string
    }]
  }
}

type EnterCodeRedeemState = StateError | StateSuccess | StateRequiresCollection

function isStateError(state: EnterCodeRedeemState): state is StateError {
  return !!(state as StateError).error
}
function isStateSuccess(state: EnterCodeRedeemState): state is StateSuccess {
  return !!(state as StateSuccess).effect
}
function isStateRequiresCollection(state: EnterCodeRedeemState): state is StateRequiresCollection {
  return !!(state as StateRequiresCollection).requires_collection
}

const sendCodeRequest = (authToken: string, code: string, collection: number = null) => {
  const data: any = {authenticity_token: authToken, code: code.trim()}
  if (collection) {
    data["collection"] = collection
  }
  return axios.post<EnterCodeRedeemState>(
    "/enter_code/redeem",
    data,
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }
  )
}

export const StickersPopupInner: React.FC<{stickers: StateSuccess["effect"]["stickers"]["granted"]}> = ({stickers}) => {
  return <>
    <PopupTitle>
      {stickers.length > 1
        ? I18n.t(b("gained_stickers_title--multiple"))
        : I18n.t(b("gained_stickers_title--single"))}
    </PopupTitle>
    <div className="neo__enterCodeRed__popup__stickers__content">
      <div className={`neo__enterCodeRed__popup__stickers__entries ${stickers.length === 1 ? "neo__enterCodeRed__popup__stickers__entries--singleSticker" : ""}`}>

        {_.map(stickers, (st, index) => {
          return <div className="neo__enterCodeRed__popup__stickers__entry" key={index}>
            <div className="neo__enterCodeRed__popup__stickers__entry__img" style={{
              backgroundImage: `url("${st.img}")`}}/>
            <h3>
              {st.already_owned && <span className="neo__enterCodeRed__popup__stickers__alreadyOwnedIcon" />}
              {st.name}
              {" "}
              <span>{st.number}</span>
            </h3>

          </div>
        })}
      </div>
      <p className="neo__enterCodeRed__popup__stickers__newInfo">
        <span className="neo__enterCodeRed__popup__stickers__alreadyOwnedIcon" />
        {" "}
        {I18n.t(b("new_sticker_info"))}
      </p>
    </div>
  </>
}

const PackImage: React.FC<{src: string}> = ({src}) => {
  const [deg] = useState(Math.random() * 10 - 5)
  return <img src={src} style={{transform: `rotate(${deg}deg)`}} />
}

export const CoinsPopupInner: React.FC<{coins: StateSuccess["effect"]["coins"]}> = ({coins}) => {
  return <>
    <PopupTitle>{I18n.t(b("gained_coins_title"), {amount: coins.gained})}</PopupTitle>
    <div className="neo__enterCodeRed__popup__coins__content">
      <div className="neo__enterCodeRed__popup__coins__rewardImg">
        <img src={coinImg} />
      </div>
      <div className="neo__enterCodeRed__popup__coins__total">{I18n.t(b("coins_total_now"), {total: coins.gained})}</div>
    </div>
  </>
}

const PopupTitle: React.FC<PropsWithChildren> = ({children}) => {
  return <div className="neo__enterCodeRed__popup__title"><h2><span><span>{children}</span></span></h2></div>
}

export const PacksPopupInner: React.FC<{packs: StateSuccess["effect"]["packs"]}> = ({packs}) => {
  return <>
    <PopupTitle>{packs.amount > 1 ? I18n.t(b("gained_packs_title--multiple"), {amount: packs.amount}) : I18n.t(b("gained_packs_title--single"))}</PopupTitle>
    <div className="neo__enterCodeRed__popup__packs__content">
      <div className="neo__enterCodeRed__popup__packs__imagesWrapper">
        {_.times(packs.amount, (i) => {
          return <div key={i} className="neo__enterCodeRed__popup__packs__imageHolder"><PackImage src={packs.pack_img} /></div>
        })}
      </div>
      <div className="neo__enterCodeRed__popup__packs__name">{packs.name}</div>
      <div className="neo__enterCodeRed__popup__packs__openHint">{I18n.t(b("how_to_open_packs_hint"))}</div>
      <div className="neo__enterCodeRed__popup__packs__openHint__image__holder">
        <div className="neo__enterCodeRed__popup__packs__openHint__image">
          <div className="neo__enterCodeRed__popup__packs__openHint__image__cover"
            style={{backgroundImage: `url("${packs.cover_img}")`}}/>
          <div className="neo__enterCodeRed__popup__packs__openHint__image__pack"
            style={{backgroundImage: `url("${packs.pack_img}")`}}/>
          <img src={packsInfoImg} />
          <div className="neo__enterCodeRed__popup__packs__openHint__image__arrow" />
        </div>
      </div>
    </div>
  </>
}

export const RequireCollectionPopupInner: React.FC<{candidates: StateRequiresCollection["requires_collection"]["candidates"], onSelect: (id: number) => void}> = ({candidates, onSelect}) => {
  return <>
    <PopupTitle>{I18n.t(b("requires_collection_title"))}</PopupTitle>
    <div className="neo__enterCodeRed__popup__requiresCollection__content">

    <div className="neo__enterCodeRed__popup__requiresCollection__entries">
      {_.map(candidates, (collection, index) => {
        return (
          <a
            key={collection.id}
            className="neo__enterCodeRed__popup__requiresCollection__entry"
            onClick={() => onSelect(collection.id)}
          >
            <div
              className="neo__enterCodeRed__popup__requiresCollection__entry__img"
              style={{backgroundImage: `url("${collection.cover_img}")`}}
            />
            <h3>{collection.name}</h3>
          </a>
        )
      })}
      </div>
    </div>
  </>
}

export const Popup: React.FC<PropsWithChildren<{onClose(): void}>> = ({children, onClose}) => {
  return <div className="neo__enterCodeRed__popup__holder">
    <div className="neo__enterCodeRed__popup__bg" onClick={() => onClose()} />
    <div className="neo__enterCodeRed__popup__closeHolder"><a onClick={() => onClose()} /></div>
    <div className="neo__enterCodeRed__popup__content">
      <div>{children}</div>
    </div>
  </div>
}

interface CompInit {
  auth_token: string
}

const EnterCodeRedeem: React.FC<{init: CompInit}> = ({init}) => {
  const [state, setState] = useState<EnterCodeRedeemState>({})

  const markEffectSeen = (type: keyof StateSuccess["effect"]) => {
    setState((pref) => {
      if (isStateSuccess(pref)) {
        const st = _.cloneDeep(pref)
        delete st.effect[type]
        return st
      }
      return pref
    })
  }

  const resetState = () => {
    setState({})
  }

  const chooseCollection = (id: number) => {
    setState((pref) => {
      const st = _.cloneDeep(pref)
      st.collection = id
      return st
    })
  }
  useEffect(() => {
    // resubmit to server if collection has been chosen
    if (state.collection) {
      sendCode()
    }
  }, [state.collection])

  const setCode = (code: string) => {
    setState((pref) => {
      const st = _.cloneDeep(pref)
      st.code = code
      return st
    })
  }

  const sendCode = () => {
    sendCodeRequest(init.auth_token, state.code || "", state.collection)
      .then((resp) => {
        const st: EnterCodeRedeemState = _.cloneDeep(resp.data)
        if (isStateSuccess(st)) {
          delete st.code
          delete st.collection
        }
        setState(st)
      })
      .catch((err) => {
        setState((pref) => {
          const st = _.cloneDeep(pref) as StateError
          st.error = `${err}`
          return st
        })
      })
  }

  return <div className="neo__enterCodeRedeem">
    <div className="neo__enterCodeRedeem__inner">
      {isStateSuccess(state) &&
        <>
          {state.effect.coins
            ? <Popup key="coins" onClose={() => markEffectSeen("coins")}>
                <CoinsPopupInner coins={state.effect.coins} />
              </Popup>
            : (state.effect.packs
              ? <Popup key="packs" onClose={() => markEffectSeen("packs")}>
                  <PacksPopupInner packs={state.effect.packs} />
                </Popup>
              : (state.effect.stickers
                ? <Popup key="stickers" onClose={() => markEffectSeen("stickers")}>
                    <StickersPopupInner stickers={state.effect.stickers.granted} />
                  </Popup>
                : undefined))}
        </>
      }
      {isStateRequiresCollection(state) &&
        <Popup key="requires_collection" onClose={() => resetState()}>
          <RequireCollectionPopupInner
            candidates={state.requires_collection.candidates}
            onSelect={chooseCollection}
          />
        </Popup>
      }
      <div className="neo__enterCodeRedeem__contentRow">
        <SectionTitle>{I18n.t(b("title"))}</SectionTitle>
      </div>

      <div className="neo__enterCodeRedeem__contentRow">
        <div className="neo__enterCodeRedeem__panel">
          <div className="neo__enterCodeRedeem__info">{I18n.t(b("info"))}</div>
          <div className="neo__enterCodeRedeem__formHolder">
            <form onSubmit={(evt) => evt.preventDefault()}>
              <div className="neo__enterCodeRedeem__inputTitle">{I18n.t(b("input_title"))}</div>
              <input
                className={`neo__enterCodeRedeem__input ${isStateError(state) ? "neo__enterCodeRedeem__input--errored" : ""}`}
                placeholder={I18n.t("enter_code.input_placeholder")}
                onKeyDown={(evt) => {if (evt.code === "Enter") sendCode() }}
                type="text"
                value={state.code || ""}
                onChange={(evt) => setCode(evt.target.value)} />
              {isStateError(state) &&
                <div className="neo__enterCodeRedeem__error">
                  {state.error}
                </div>}
              <Button
                onClick={() => sendCode()}
                disabled={!state.code || state.code.trim().length === 0}>{I18n.t(b("action_redeem"))}</Button>
            </form>
          </div>
          <div className="neo__enterCodeRedeem__belowInfo">{I18n.t(b("info_below--md"))}</div>
        </div>
      </div>
      <LogoutButtonHolder><LogoutButton /></LogoutButtonHolder>
    </div>
  </div>
}

export default EnterCodeRedeem
