import React from "react"

import { PageTitle, SectionTitle, Block, Note } from '../../pages/sandbox'
import _ from "lodash"
import { initState } from "../state"
import { MockContextProvider } from "../context"
import shopAlbumImg from "@images/sandbox__shopAlbum.png"
import shopYearbookImg from "@images/sandbox__shopYearbook.png"
import shopStickersImg from "@images/sandbox__shopStickers.png"
import shopCoins1Img from "@images/sandbox__shopCoins1.png"
import shopCoins2Img from "@images/sandbox__shopCoins2.png"
import shopCoins3Img from "@images/sandbox__shopCoins3.png"
import stickerImg from "@images/sandbox__sticker.jpg"
import { ShopItemImage } from "../types"
import ViewCart from "./ViewCart"

const itemImg = (src: string): ShopItemImage => {return {large: src, thumb: src}}

const Sandbox:React.FC = () => {
  return <>
    <Block>
      <MockContextProvider state={{...initState, cart: {
        restricted_to_countries: [
          "Germany", "France", "Poland", "Spain", "Lummerland", "Green Hills", "Netherlands"
        ],
        entries: [
          {
            item: {name: "Super Yearbook 2k", description: "Best of it all", price: {value: "1000,99€", subtitle: "per item"}, sku: "SANDBOX_YEARBOOK", image: itemImg(shopYearbookImg)},
            amount_info: {
              amount: 1,
              can_change: true,
              max: 10,
              total_price: {
                value: "99€",
                subtitle: "total"
              }
            }
          },
        ]}}}
      >
        <ViewCart />
      </MockContextProvider>
    </Block>

    <Block>
      <Note>Can't change amount</Note>
      <MockContextProvider state={{...initState, cart: {
        restricted_to_countries: [
          "Germany", "France", "Poland", "Spain", "Lummerland", "Green Hills", "Netherlands"
        ],
        entries: [
          {
            item: {name: "Super Yearbook 2k", description: "Best of it all", price: {value: "1000,99€", subtitle: "per item"}, sku: "SANDBOX_YEARBOOK", image: itemImg(shopYearbookImg)},
            amount_info: {
              amount: 1,
              can_change: false,
              max: 10,
              total_price: {
                value: "99€",
                subtitle: "total"
              }
            }
          },
        ]}}}
      >
        <ViewCart />
      </MockContextProvider>
    </Block>

    <Block>
      <Note>For XL-Stickers don't say "Copies" but "Stickers" for amount label</Note>
      <MockContextProvider state={{...initState, cart: {
        restricted_to_countries: [
          "Germany", "France", "Poland", "Spain", "Lummerland", "Green Hills", "Netherlands"
        ],
        entries: [
          {
            item: {name: "XL Stickers", configuration_type: "select_stickers" ,description: "Best of it all", price: {value: "1000,99€", subtitle: "per item"}, sku: "SANDBOX_YEARBOOK", image: itemImg(shopYearbookImg)},
            amount_info: {
              amount: 5,
              can_change: false,
              max: 10,
              total_price: {
                value: "99€",
                subtitle: "total"
              }
            }
          },
        ]}}}
      >
        <ViewCart />
      </MockContextProvider>
    </Block>
  </>
}

export default ["ec/Cart", Sandbox]
