import React from "react"

import { PageTitle, SectionTitle, Block, Note } from '../../pages/sandbox'
import _ from "lodash"
import DedicatedViewOrders from "./DedicatedViewOrders"
import { WithMockApiPost } from "@root/misc/jsonPostApi"

const Sandbox:React.FC = () => {
  return <WithMockApiPost>
    <Block>
      <Note>some</Note>
      <DedicatedViewOrders pageInfo={{page: 1, total_pages: 5}} orders={[
        {
          order_id: 999,
          order_number: "#5678908765",
          description: "Prefilled album",
          ordered_at: "Sep 1st 2023",
          url: "/order/999",
        },
        {
          order_id: 998,
          order_number: "#12348908765",
          description: "12x XL Stickers",
          ordered_at: "Jul 23rd 2023",
          url: "/order/999",
        },
        {
          order_id: 997,
          order_number: "#12348908764",
          description: "2.000 Coins",
          ordered_at: "Jul 21rd 2023",
          url: "/order/999",
        },
      ]} />
    </Block>
    <Block>
      <Note>none</Note>
      <DedicatedViewOrders orders={[]} pageInfo={{page: 1, total_pages: 1}} />
    </Block>
  </WithMockApiPost>
}

export default ["ec/Orders", Sandbox]
