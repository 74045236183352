import React, { useEffect, useRef } from "react"
import { Provider } from "react-redux"
import { ECommerceState } from "./state"
import { configureStore } from "@reduxjs/toolkit"
import { JSON_POST_API_CONTEXT_KEY, createSagaJSONPostApiContext } from "@root/misc/jsonPostApi"
import createSagaMiddleware from "redux-saga"
import reducer from "./reducer"
import mainSaga from "./saga"
import { ReduxECommerceContext, useECommerce } from "./context"
import AvailableItems from "./views/AvailableItems"
import DisplayErrorOverlay from "./views/DisplayErrorOverlay"
import ViewCart from "./views/ViewCart"
import ViewSelectStickers from "./views/ViewSelectStickers"
import BusyOverlay from "./views/BusyOverlay"
import ViewAddresses from "./views/ViewAddresses"
import ViewCreateOrEditAddress from "./views/ViewCreateOrEditAddress"
import ViewSelectShipping from "./views/ViewSelectShipping"
import ViewSummary from "./views/ViewSummary"
import ViewOrder from "./views/ViewOrder"

const getStore = (init: ECommerceState) => {
  const storeRef = useRef<ReturnType<typeof configureStore>>()

  if (!storeRef.current) {
    const context = {} as any
    context[JSON_POST_API_CONTEXT_KEY] = createSagaJSONPostApiContext(init.formAuthToken, init.authorization, init.locale)
    const sagaMiddleware = createSagaMiddleware({ context })

    storeRef.current = configureStore({
      reducer: reducer,
      middleware: [sagaMiddleware],
      preloadedState: init
    })
    sagaMiddleware.run(mainSaga)
  }
  return storeRef.current
}

const ECommerceInner: React.FC = () => {
  const {state, dispatch} = useECommerce()

  useEffect(() => { // scroll to top when navigation changes
    dispatch({type: "navigation__scrollToTop"})
  }, [
    state.navigation.page,
    state.navigation.page === "addresses" ? state.navigation.addressScope : "-"
  ])

  return <>
    <DisplayErrorOverlay />
    <BusyOverlay />
    {state.navigation.page === "landing" && <AvailableItems />}
    {state.navigation.page === "cart" && <ViewCart />}
    {state.navigation.page === "selectStickers" && <ViewSelectStickers item={state.navigation.item} />}
    {state.navigation.page === "addresses" && <ViewAddresses key={state.navigation.addressScope} addressScope={state.navigation.addressScope} needsShipping={state.navigation.needsShipping} />}
    {state.navigation.page === "createOrEditAddress" && <ViewCreateOrEditAddress address={state.navigation.address} backTo={state.navigation.addressScope} needsShipping={state.navigation.needsShipping} suggestedCountryUid={state.navigation.suggestedCountryUid} />}
    {state.navigation.page === "selectShipping" && <ViewSelectShipping />}
    {state.navigation.page === "summary" && <ViewSummary />}
    {state.navigation.page === "order" && <ViewOrder orderId={state.navigation.orderId} />}
  </>
}

const ECommerce: React.FC<{init: ECommerceState}> = ({init}) => {
  const store = getStore(init)

  return <Provider store={store}>
    <ReduxECommerceContext>      
      <ECommerceInner />
    </ReduxECommerceContext>
  </Provider>
}

export default ECommerce
