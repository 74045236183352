import React from "react"

import { PageTitle, SectionTitle, Block, Note } from '../../pages/sandbox'
import _ from "lodash"
import { initState } from "../state"
import { MockContextProvider } from "../context"
import { ShopItemImage } from "../types"
import ViewAddresses from "./ViewAddresses"

const itemImg = (src: string): ShopItemImage => {return {large: src, thumb: src}}

const Sandbox:React.FC = () => {
  return <>
    <Block>
      <Note>Billing, should send to shipping</Note>
      <MockContextProvider state={{...initState,
        addresses: {
          entries: [{city: "Bochum", country: {name: "Germany", uid: "GER"}, firstname: "Sonic", lastname: "Hedgehog", postcode: "12345", street: ["Streeeeet", "62"], id: 99999123, telephone: "(+49) 12345678"}],
          scope: "billing"
        }
      }}>
        <ViewAddresses addressScope="billing" needsShipping />
      </MockContextProvider>
    <Block>
      <Note>Empty list</Note>
      <MockContextProvider state={{...initState,
        addresses: {
          entries: [],
          scope: "billing"
        }
      }}>
        <ViewAddresses addressScope="billing" needsShipping />
      </MockContextProvider>
    </Block>
      <Note>Shipping</Note>
      <MockContextProvider state={{...initState,
        addresses: {
          entries: [
            {city: "Bochum", country: {name: "Germany", uid: "GER"}, firstname: "", lastname: "", postcode: "12345", street: ["Streeeeet 62"], id: 99999123, telephone: "(+49) 12345678"},
            {city: "Bochum", country: {name: "Germany", uid: "GER"}, firstname: "", lastname: "", postcode: "12345", street: ["Streeeeet 62"], id: 99999124, telephone: "(+49) 12345678"}
          ],
          scope: "shipping"
        }
      }}>
        <ViewAddresses addressScope="shipping" needsShipping />
      </MockContextProvider>
    </Block>
  </>
}

export default ["ec/Addresses", Sandbox]
