import _ from "lodash"
import { Reducer } from "react"
import { ECommerceActions } from "../actions"
import { ECommerceState } from "../state"
import { isServerError } from "../types"
import paymentReducer from "./paymentReducer"

const busyReducers = (state: ECommerceState, action: ECommerceActions) => {
  switch (action.type) {
    case "busy__setBusy":
      return {
        ...state,
        busy: true
      } as ECommerceState
    case "busy__setNotBusy":
      return {
        ...state,
        busy: false
      } as ECommerceState
  }
  return state
}

const navigationReducers = (state: ECommerceState, action: ECommerceActions) => {
  switch (action.type) {
    case "navigation__set":
      return {
        ...state,
        navigation: action.data.navigation
      } as ECommerceState
  }
  return state
}

const errorReducers = (state: ECommerceState, action: ECommerceActions) => {
  switch (action.type) {
    case "displayError__set":
      return {
        ...state,
        error: action.data
      } as ECommerceState
    case "displayError__clear":
      const res = _.clone(state)
      delete res.error
      return res
  }
  return state
}

const orderReducer = (state: ECommerceState, action: ECommerceActions) => {
  switch (action.type) {
    case "api__receive__ecommerce/get_order":
      if (isServerError(action.data)) {
        return {
          ...state,
          error: {error: action.data.error.description}
        } as ECommerceState
      } else {
        return {
          ...state,
          order: action.data
        } as ECommerceState
      }
  }
  return state
}

const ecommerceReducer = (state: ECommerceState, action: ECommerceActions) => {
  switch (action.type) {
    case "ecommerce__setScopeInfoFromServer":
      return {
        ...state,
        scopeInfoFromServer: action.data
      } as ECommerceState
    case "ecommerce__clearCart":
      const res = _.clone(state)
      delete res.cart
      return res
    case "ecommerce__clearItemConfigurationInfo":
      const res2 = _.clone(state)
      delete res2.itemConfigurationInfo
      return res2
    case "ecommerce__clearAddresses":
      const res3 = _.clone(state)
      delete res3.addresses
      return res3
    case "ecommerce__clearSummary":
      const res4 = _.clone(state)
      delete res4.summary
      return res4
    case "ecommerce__clearCountries":
      return {
        ...state,
        createOrEditAddress: state.createOrEditAddress
          ? {
              ...state.createOrEditAddress,
              availableCountries: undefined
            }
          : undefined
      } as ECommerceState
    case "ecommerce__clearCountryOptions":
      return {
        ...state,
        createOrEditAddress: state.createOrEditAddress
          ? {
              ...state.createOrEditAddress,
              countryOptions: undefined
            }
          : undefined
      } as ECommerceState
    case "ecommerce__clearCreateOrEditErrorInfo":
      return {
        ...state,
        createOrEditAddress: state.createOrEditAddress
          ? {
              ...state.createOrEditAddress,
              error: undefined,
              errorFields: undefined
            }
          : undefined
      } as ECommerceState
    case "ecommerce__clearCreateOrEditData":
      return {
        ...state,
        createOrEditAddress: undefined
      } as ECommerceState
  }
  return state
}

const serverResponseReducers = (state: ECommerceState, action: ECommerceActions) => {
  switch (action.type) {
    case "api__receive__ecommerce/get_available_items":
      if (isServerError(action.data)) {
        return {
          ...state,
          error: {error: action.data.error.description, requiresReload: true}
        } as ECommerceState
      } else {
        return {
          ...state,
          availableItems: action.data.items
        } as ECommerceState
      }
    case "api__receive__ecommerce/item_get_configuration_info":
      if (isServerError(action.data)) {
        return {
          ...state,
          error: {error: action.data.error.description, requiresReload: true}
        } as ECommerceState
      } else {
        return {
          ...state,
          itemConfigurationInfo: action.data
        } as ECommerceState
      }
    case "api__receive__ecommerce/get_cart":
    case "api__receive__ecommerce/cart_add_item":
    case "api__receive__ecommerce/cart_change_item_amount":
    case "api__receive__ecommerce/cart_remove_item":
      if (isServerError(action.data)) {
        return {
          ...state,
          error: {error: action.data.error.description, requiresReload: true}
        } as ECommerceState
      } else {
        return {
          ...state,
          cart: action.data.cart
        } as ECommerceState
      }
    case "api__receive__ecommerce/get_addresses":
    case "api__receive__ecommerce/address_delete":
      if (isServerError(action.data)) {
        return {
          ...state,
          error: {error: action.data.error.description}
        } as ECommerceState
      } else {
        return {
          ...state,
          addresses: {
            ...state.addresses,
            scope: action.data.address_scope,
            entries: action.data.addresses
          }
        } as ECommerceState
      }
    case "api__receive__ecommerce/get_summary":
    case "api__receive__ecommerce/coupon_remove_code":
    case "api__receive__ecommerce/coupon_use_code":
      if (isServerError(action.data)) {
        return {
          ...state,
          error: {error: action.data.error.description}
        } as ECommerceState
      } else {
        return {
          ...state,
          summary: action.data.summary
        } as ECommerceState
      }
    case "api__receive__ecommerce/address_create_or_update":
      if (isServerError(action.data)) { // inject error info
        return {
          ...state,
          createOrEditAddress: {
            ...state.createOrEditAddress,
            error: action.data.error.description,
            errorFields: (action.data as any).error_fields
          }
        } as ECommerceState
      } else { // saved, clear createOrEditAddress-info
        return {
          ...state,
          addresses: {
            ...state.addresses,
            scope: action.data.address_scope,
            entries: action.data.addresses
          },
          createOrEditAddress: undefined
        } as ECommerceState
      }    
    case "api__receive__ecommerce/address_get_available_countries":
      if (isServerError(action.data)) { // inject error info
        return {
          ...state,
          error: {error: action.data.error.description}
        } as ECommerceState
      } else { // set countries
        return {
          ...state,
          createOrEditAddress: {
            ...state.createOrEditAddress,
            availableCountries: action.data.available_countries,
            countryOptions: undefined
          }
        } as ECommerceState
      }    
    case "api__receive__ecommerce/address_get_info_for_country":
      if (isServerError(action.data)) { // inject error info
        return {
          ...state,
          error: {error: action.data.error.description}
        } as ECommerceState
      } else { // set countries
        return {
          ...state,
          createOrEditAddress: {
            ...state.createOrEditAddress,
            countryOptions: {
              loading: false,
              regions: action.data.available_regions,
              streetLabels: action.data.street_inputs_labels
            }
          }
        } as ECommerceState
      }    
    case "api__receive__ecommerce/address_select":
      if (isServerError(action.data)) { // inject error info
        return {
          ...state,
          error: {error: action.data.error.description}
        } as ECommerceState
      } else { // get shippingOptions
        if (action.data.address_scope !== "shipping") {
          return state
        }
        return {
          ...state,
          shippingOptions: action.data.shipping_options || []
        } as ECommerceState
      }
  }
  return state
}

const rootReducer: Reducer<ECommerceState, ECommerceActions> = (state, action) => {
  const stateClone = {...state}
  return _.reduce([
    ecommerceReducer,
    busyReducers,
    errorReducers,
    navigationReducers,
    serverResponseReducers,
    paymentReducer,
    orderReducer,
  ], (prevState, reducer) => reducer(prevState, action), stateClone)
}

export default rootReducer
