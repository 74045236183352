import ECommerce from "@root/ecommerce/ECommerce"
import { Authorization, PageInfo } from "@root/ecommerce/types"
import DedicatedViewOrders, { OrdersListEntry } from "@root/ecommerce/views/DedicatedViewOrders"
import { DedicatedOrderPage } from "@root/ecommerce/views/ViewOrder"
import { WithApiPost } from "@root/misc/jsonPostApi"
import React, { useContext, useState } from "react"

export type CompInit = PageInfo & {
  form_authenticity_token: string
  authorization: Authorization
  locale: string
  shop: string

  orders: OrdersListEntry[]
}

export const OrdersMount: React.FC<{init: CompInit}> = ({init}) => {
  return <WithApiPost auth={init.authorization} authToken={init.form_authenticity_token} locale={init.locale}>
    <DedicatedViewOrders orders={init.orders} pageInfo={init}/>
  </WithApiPost>
}

export default OrdersMount
