import React, { PropsWithChildren } from "react"
import Button from "./Button"
import I18n from "i18n-js"

const LOGOUT_PATH = "/session/logout"

const LogoutButton: React.FC<PropsWithChildren> = ({children}) => {
  return <Button href={LOGOUT_PATH}>
    {children || I18n.t("main_menu.more_menu.item.logout.label")}
  </Button>
}

export const LogoutButtonHolder: React.FC<PropsWithChildren> = ({children}) => {
  return <div className="neo__logoutButtonHolder">{children}</div>
}

export default LogoutButton
