import React, { CSSProperties, forwardRef } from "react"
import NukaCarousel, {PagingDots} from "nuka-carousel"

type Props = React.ComponentProps<typeof NukaCarousel>

const ContainerStyles: CSSProperties = {
  marginBottom: "30px",
}

const DotsStyles: CSSProperties = {
  position: "relative",
  top: "40px", // NukaCarousel always offsets the dots by -10px
  display: "flex",
}

const Carousel: React.FC<Props> = forwardRef((props, ref) => {
  const {children, ...rest} = props
  return (
    <div style={ContainerStyles}>
      <NukaCarousel
        autoplay={true}
        autoplayInterval={5000}
        // no left & right controls
        renderCenterLeftControls={() => null}
        renderCenterRightControls={() => null}
        // push "dots" down
        renderBottomCenterControls={(props) => {
          return (
            <div style={DotsStyles}>
              <PagingDots {...props} />
            </div>
          )
        }}
        wrapAround
        ref={ref}
        {...rest}
      >
        {props.children}
      </NukaCarousel>
    </div>
  )
})

export default Carousel