import Rails from "@rails/ujs"
import React from "react"
import { createRoot } from "react-dom/client"

import { setup as setupGigya } from "./gigya"
import { setup as setupWebview } from "./webview"

import "normalize.css"
import "./stylesheets/application.scss"

import I18n from "i18n-js"

I18n.defaultLocale = (window as any).__localization.locale
I18n.locale = (window as any).__localization.locale
I18n.translations = (window as any).__localization.translations

function importAll(r: any) { r.keys().forEach(r) }

importAll(require.context("./images", true, /.(png|jpg|svg)$/))

console.log("First in pack: application")
Rails.start()

setupGigya()
setupWebview()

const pageContext = require.context("./pages", false)
importAll(pageContext)

pageContext.keys().forEach((key) => {
  const match = key.match(/\.\/(\w+)\./)
  if (!match) { return }
  const container = document.getElementById(`page-${match[1]}`)
  if (!container) { return }
  const root = createRoot(container)
  const Page = pageContext(key).default
  const init = JSON.parse(container.getAttribute("data-init") || "{}")
  document.addEventListener("DOMContentLoaded", () => {
    root.render(<Page init={init} />)
  })
})
