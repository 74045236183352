import React, { ChangeEvent, useEffect, useState } from "react"
import _ from "lodash"
import { useECommerce } from "../context"
import I18n from "i18n-js"
import { Address, AddressRegion, AddressScope } from "../types"
import Button from "@root/components/Button"
import { ActionLinkHolder, ActionsHolder, ConfirmAction, FormHolder, FormLabelAndInput, FormRow, PageWrapper, Panel, Row, Title } from "../Ui"

const isSet = (val: any): boolean => {
  if (!val) return false
  if (val === 0) return false
  if (val === "") return false
  if (_.isArray(val)) {
    if (val.length === 0) return false
    return _.some(val, (v) => isSet(v))
  }
  return true
}

const ViewCreateOrEditAddress: React.FC<{address?: Address, suggestedCountryUid?: string, backTo: AddressScope, needsShipping: boolean}> = ({address, backTo, needsShipping, suggestedCountryUid}) => {
  const {state, dispatch} = useECommerce()

  const [vals, setVals] = useState<Partial<Address>>(address ? _.cloneDeep(address) : {})

  useEffect(() => { // scroll to top when new error
    if ((state.createOrEditAddress?.error || "").length > 0) {
      dispatch({type: "navigation__scrollToTop"})
    }
  }, [state.createOrEditAddress?.error || ""])

  useEffect(() => { // preselect country if nothing initially picked
    if (suggestedCountryUid && !vals.country) {
      __changeCountryByUid(suggestedCountryUid)
    }
    return undefined
  }, [])

  const updateSimpleValCallback = (which: keyof Address) => {
    return (evt: ChangeEvent<HTMLInputElement>) => {
      setVals((prev) => {
        const newVals = _.cloneDeep(prev)
        ;(newVals as any)[which] = evt.target.value
        return newVals
      })
    }
  }

  let allValsSet = true
    && isSet(vals.firstname)
    && isSet(vals.lastname)
    && isSet(vals.city)
    && isSet(vals.country)
    && (!!state.createOrEditAddress?.countryOptions)
    && ((state.createOrEditAddress?.countryOptions?.streetLabels || []).length === 0 || isSet(vals.street))
    && (isSet(vals.region) && isSet(vals.region.name)) // note: region with an ID always have also a name
    && isSet(vals.postcode)
    && isSet(vals.telephone)

  const fieldHasError = (field: keyof Address) => {
    if (!state.createOrEditAddress) return false
    if (!state.createOrEditAddress.errorFields) return false
    return _.includes(state.createOrEditAddress.errorFields, field)
  }

  const changeStreetAtIndex = (evt: ChangeEvent<HTMLInputElement>, changedIndex: number) => {
    if (!state.createOrEditAddress?.countryOptions?.streetLabels) { return }
    setVals((prev) => {
      const newVals = _.cloneDeep(prev)
      let newStreets: string[] = []
      _.each(state.createOrEditAddress.countryOptions.streetLabels, (lab, index) => {
        newStreets.push(
          index === changedIndex
            ? evt.target.value || ""
            : vals.street?.[index] || "")
      })
      newVals.street = newStreets
      return newVals
    })
  }

  const changeRegionFromOptions = (evt: ChangeEvent<HTMLSelectElement>) => {
    setVals((prev) => {
      const newVals = _.cloneDeep(prev)
      if (evt.target.value === "") {
        delete newVals.region
      } else if (state.createOrEditAddress?.countryOptions?.regions) {
        _.each(state.createOrEditAddress.countryOptions.regions, (r) => {
          if (r.id === parseInt(evt.target.value)) {
            newVals.region = r
          }
        })
      }
      return newVals
    })
  }

  const changeRegionFromFreetext = (evt: ChangeEvent<HTMLInputElement>) => {
    setVals((prev) => {
      const newVals = _.cloneDeep(prev)
      newVals.region = {
        id: 0,
        name: evt.target.value || ""
      }
      return newVals
    })
  }

  const __changeCountryByUid = (countryUid: string) => {
    setVals((prev) => {
      const newVals = _.cloneDeep(prev)
      if (countryUid === "") {
        delete newVals.country
      } else if (state.createOrEditAddress?.availableCountries) {
        _.each(state.createOrEditAddress.availableCountries, (country) => {
          if (country.uid === countryUid) {
            newVals.country = country
            delete newVals.region // delete region on country change
            dispatch({type: "ecommerce__requestOptionsForCountry", data: {addressScope: backTo, countryUid: country.uid}})
          }
        })
      }
      return newVals
    })
  }

  const changeCountry = (evt: ChangeEvent<HTMLSelectElement>) => {
    __changeCountryByUid(evt.target.value)
  }

  const countryInformationReady = !!state.createOrEditAddress?.availableCountries
  const regionInformationReady = !!state.createOrEditAddress?.countryOptions?.regions
  const needsRegionFromOptions = state.createOrEditAddress?.countryOptions?.regions && state.createOrEditAddress.countryOptions.regions.length > 0

  return <PageWrapper>
    <Row>
      <Title>{I18n.t(`ecommerce.address.form.title_${address ? "edit" : "create"}--${backTo}`)}</Title>
    </Row>
    {state.createOrEditAddress?.error &&
      <Row>
        <Panel size="slim" style="transparent" fullContent>
          <div className="neo__ec__addressForm__errorDesc">
            {state.createOrEditAddress.error}
          </div>
        </Panel>
      </Row>}
    <Row>
      <Panel size="slim" style="transparent" fullContent>
        <div className="neo__ec__addressForm__info">{I18n.t("ecommerce.address.form.info_mandatory")}</div>
      </Panel>
    </Row>
    <form onSubmit={(evt) => evt.preventDefault()}>
      <Row>
        <Panel size="slim" style="box"><FormHolder>
          <FormRow>
            <FormLabelAndInput
              label={I18n.t("ecommerce.address.form.firstname")}
              hasError={fieldHasError("firstname")}>
                <input className="neo__ec__textInput" type="text" value={vals["firstname"] || ""} onChange={updateSimpleValCallback("firstname")} />
            </FormLabelAndInput>
            <label></label>
          </FormRow>
          <FormRow>
            <FormLabelAndInput
              label={I18n.t("ecommerce.address.form.lastname")}
              hasError={fieldHasError("lastname")}>
                <input className="neo__ec__textInput" type="text" value={vals["lastname"] || ""} onChange={updateSimpleValCallback("lastname")} />
            </FormLabelAndInput>
          </FormRow>
          <FormRow>
            <FormLabelAndInput
              label={I18n.t("ecommerce.address.form.country")}
              hasError={fieldHasError("country")}>
                {countryInformationReady
                  ? <>
                      <select className="neo__ec__textInput" onChange={changeCountry} value={_.isString(vals.country?.uid) ? vals.country.uid : ""}>
                        <option value="" disabled>{I18n.t("ecommerce.address.form.please_choose")}</option>
                        {_.map(state.createOrEditAddress.availableCountries, (country) => {
                          return <option key={country.uid} value={country.uid}>{country.name}</option>
                        })}
                      </select>
                    </>
                  : <>
                      <span className="neo__ec__addressForm__waitPlaceholder">{I18n.t("ecommerce.address.form.please_wait")}</span>
                    </>}
            </FormLabelAndInput>
          </FormRow>
          {countryInformationReady &&
            <FormRow>
              <FormLabelAndInput
                label={I18n.t("ecommerce.address.form.region")}
                hasError={fieldHasError("region")}>
                {regionInformationReady
                  ? <>
                      {needsRegionFromOptions
                        ? <>
                            <select className="neo__ec__textInput" onChange={changeRegionFromOptions} value={_.isNumber(vals.region?.id) ? vals.region.id : ""}>
                              <option value="" disabled>{I18n.t("ecommerce.address.form.please_choose")}</option>
                              {_.map(state.createOrEditAddress.countryOptions.regions, (r) => {
                                return <option key={r.id} value={r.id}>{r.name}</option>
                              })}
                            </select>
                          </>
                        : <>
                            <input className="neo__ec__textInput" type="text" value={vals["region"]?.["name"] || ""} onChange={changeRegionFromFreetext} />
                          </>
                        }
                    </>
                  : <>
                      <span className="neo__ec__addressForm__waitPlaceholder">{I18n.t("ecommerce.address.form.please_wait")}</span>
                    </>
                  }
              </FormLabelAndInput>
            </FormRow>
          }
          <FormRow>
            <FormLabelAndInput
              label={I18n.t("ecommerce.address.form.postcode")}
              hasError={fieldHasError("postcode")}>
                <input className="neo__ec__textInput" type="text" value={vals["postcode"] || ""} onChange={updateSimpleValCallback("postcode")} />
            </FormLabelAndInput>
          </FormRow>
          <FormRow>
            <FormLabelAndInput
              label={I18n.t("ecommerce.address.form.city")}
              hasError={fieldHasError("city")}>
                <input className="neo__ec__textInput" type="text" value={vals["city"] || ""} onChange={updateSimpleValCallback("city")} />
            </FormLabelAndInput>
          </FormRow>
          <FormRow>
            {state.createOrEditAddress?.countryOptions?.streetLabels
              ? <>
                  {_.map(state.createOrEditAddress.countryOptions.streetLabels, (lab, index) => {
                    return <FormRow tinyMargin key={index}>
                      <FormLabelAndInput
                        label={lab}
                        hasError={fieldHasError("street")}>
                          <input className="neo__ec__textInput" type="text" value={vals.street?.[index] || ""} onChange={(evt) => {changeStreetAtIndex(evt, index)}} />
                      </FormLabelAndInput>
                    </FormRow>
                  })}
                </>
              : <FormRow tinyMargin>
                  <FormLabelAndInput
                    label={I18n.t("ecommerce.address.form.street_fallback")}
                    hasError={fieldHasError("street")}>
                      <span className="neo__ec__addressForm__waitPlaceholder">{I18n.t("ecommerce.address.form.please_wait")}</span>
                  </FormLabelAndInput>
                </FormRow>}
          </FormRow>
          <FormRow>
            <FormLabelAndInput
              label={I18n.t("ecommerce.address.form.telephone")}
              hasError={fieldHasError("telephone")}>
                <input className="neo__ec__textInput" type="text" value={vals["telephone"] || ""} onChange={updateSimpleValCallback("telephone")} />
            </FormLabelAndInput>
          </FormRow>
        </FormHolder></Panel>
      </Row>
      <Row>
        <ActionsHolder>
          <Button disabled={!allValsSet} onClick={() => dispatch({type: "ecommerce__tryToSaveNewOrEditedAddress", data: {address: vals as Address, addressScope: backTo, needsShipping}})}>
            {I18n.t("ecommerce.address.form.action_save")}
          </Button>
          {address && <ConfirmAction
            actionLabel={I18n.t("ecommerce.address.form.action_delete")}
            action={() => dispatch({type: "ecommerce__deleteAddress", data: {addressId: address.id, addressScope: backTo, needsShipping}})}
            confirmQuestion={I18n.t("ecommerce.address.form.action_delete_confirm")}
            confirmYes={I18n.t("ecommerce.address.form.action_delete_confirm_yes")}
            confirmNo={I18n.t("ecommerce.address.form.action_delete_confirm_no")}
            />}
          <ActionLinkHolder>
            <a onClick={() => dispatch({type: "ecommerce__dontSaveNewAddressJustGoBack", data: {addressScope: backTo, needsShipping}})}>
              {I18n.t("ecommerce.address.form.action_back")}
            </a>
          </ActionLinkHolder>
        </ActionsHolder>
      </Row>
    </form>
  </PageWrapper>
}

export default ViewCreateOrEditAddress
