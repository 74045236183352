import React from "react"

import { PageTitle, SectionTitle, Block, Note } from './sandbox'
import _ from "lodash"
import EnterCode from "./enterCode"
import { AppLinks } from "@root/types"

const Sandbox:React.FC = () => {
  const applinks: AppLinks = {
    android: "android-url",
    ios: "ios-url"
  }

  return <>
    <PageTitle>Enter Code (Explanation page)</PageTitle>

    <SectionTitle>Logged in</SectionTitle>
    <Block>
      <EnterCode init={{applinks, logged_in: true}} />
    </Block>

    <SectionTitle>Not logged in</SectionTitle>
    <Block>
      <EnterCode init={{applinks, logged_in: false}} />
    </Block>
  </>
}

export default Sandbox
