import React, { useEffect, useState } from "react"
import _ from "lodash"
import { useECommerce } from "../context"
import I18n from "i18n-js"
import { ActionLinkHolder, ActionsHolder, Info, PageWrapper, Row, Title } from "../Ui"
import Button from "@root/components/Button"

const ViewSelectShipping: React.FC = () => {
  const {state, dispatch} = useECommerce()

  return <PageWrapper>
    <Row>
      <Title>{I18n.t("ecommerce.select_shipping.title")}</Title>
    </Row>
    <Row>
      <Info>{I18n.t("ecommerce.select_shipping.info")}</Info>
    </Row>
    <Row>
      <ActionsHolder>
        {_.map(state.shippingOptions || [], (option) => {
          return <Button key={`${option.carrier_code}--${option.method_code}`} onClick={() => dispatch({type: "ecommerce__selectShippingOption", data: {shippingOption: option}})}>
              {I18n.t("ecommerce.select_shipping.action_continue", {name: option.title, price: option.price_value})}
            </Button>
        })}
        <ActionLinkHolder>
          <a onClick={() => dispatch({type: "navigation__set", data: {navigation: {page: "addresses", addressScope: "shipping", needsShipping: true}}})}>
            {I18n.t("ecommerce.select_shipping.action_back")}
          </a>
        </ActionLinkHolder>
      </ActionsHolder>
    </Row>
  </PageWrapper>
}

export default ViewSelectShipping
