import React, { useEffect, useState } from "react"

import { PageTitle, SectionTitle, Block, Note } from '../../pages/sandbox'
import _ from "lodash"
import { initState } from "../state"
import { MockContextProvider } from "../context"
import BusyOverlay from "./BusyOverlay"
import DisplayErrorOverlay from "./DisplayErrorOverlay"

const Sandbox:React.FC = () => {
  const [overlay, setOverlay] = useState<"error" | "busy">()

  useEffect(() => {
    let timeout: number
    if (!_.isUndefined(overlay)) {
      timeout = window.setTimeout(() => setOverlay(undefined), 3000)
    }
    return () => { // cleanup
      if (_.isNumber(timeout)) { window.clearTimeout(timeout)}
    }
  })

  return <>
    <Note>
      <ul>
        <li><a onClick={() => setOverlay("busy")}>Show busy indicator for 3 sec</a></li>
        <li><a onClick={() => setOverlay("error")}>Show error popup for 3 sec</a></li>
      </ul>
    </Note>
    <Block>
      <MockContextProvider state={{...initState,
        error: overlay === "error" ? {error: "this is an error message!"} : undefined,
        busy: overlay === "busy" ? true : undefined,
        }}>
        <>
          <BusyOverlay />
          <DisplayErrorOverlay />
        </>
      </MockContextProvider>
    </Block>
  </>
}

export default ["ec/Overlays", Sandbox]
