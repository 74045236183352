import React, { PropsWithChildren, createContext, useContext } from "react"
import { ECommerceState, initState } from "./state"
import { useSelector, useDispatch } from "react-redux"
import { Dispatch } from "@reduxjs/toolkit"
import { ECommerceActions } from "./actions"

const ECommerceContext = createContext<{state: ECommerceState, dispatch(action: ECommerceActions): void}>({state: initState, dispatch() {}})

export const ReduxECommerceContext: React.FC<PropsWithChildren> = ({children}) => {
  const state = useSelector((s: ECommerceState) => s)
  const dispatch = useDispatch<Dispatch<ECommerceActions>>()

  return <ECommerceContext.Provider value={{state, dispatch}}>
    {children}
  </ECommerceContext.Provider>
}

export const MockContextProvider: React.FC<PropsWithChildren<{state: ECommerceState}>> = ({children, state}) => {
  const dispatch = (action: ECommerceActions) => console.log(action.type, "data" in action ? action.data : "")
  return <ECommerceContext.Provider value={{state, dispatch}}>
    {children}
  </ECommerceContext.Provider>
}

export const useECommerce = () => useContext(ECommerceContext)
