import React, { useState } from "react"

import { PageTitle, SectionTitle, Block, Note } from './sandbox'
import _ from "lodash"
import { CoinsPopupInner, PacksPopupInner, Popup, StickersPopupInner, RequireCollectionPopupInner } from "./enterCodeRedeem"

import coverImg from "@images/sandbox__cover.jpg"
import packImg from "@images/sandbox__pack.jpg"
import stickerImg from "@images/sandbox__sticker.jpg"

const Sandbox:React.FC = () => {
  return <>
    <PageTitle>Enter Code Redeem Form</PageTitle>

    <RedeemPopups/>
    <RequiresCollectionPopups />
  </>
}

const RedeemPopups: React.FC = () => {
  const [popup, setPopup] = useState<"packs" | "single-pack"| "stickers" | "single-sticker" | "coins">()

  return <>
    <SectionTitle>Redeem popups</SectionTitle>
    <Note>
      <ul>
        <li><a onClick={() => setPopup("single-sticker")}>Single sticker</a></li>
        <li><a onClick={() => setPopup("stickers")}>Some stickers</a></li>
        <li><a onClick={() => setPopup("coins")}>Coins</a></li>
        <li><a onClick={() => setPopup("single-pack")}>Single pack</a></li>
        <li><a onClick={() => setPopup("packs")}>5 packs</a></li>
      </ul>
    </Note>
    <Block>
      {popup === "coins" &&
        <Popup onClose={() => setPopup(undefined)}>
          <CoinsPopupInner coins={{gained: 200, total_now: 2500}} />
        </Popup>}
      {popup === "single-sticker" &&
        <Popup onClose={() => setPopup(undefined)}>
          <StickersPopupInner stickers={[
            {img: stickerImg, name: "Sonic Hedgehog", number: 99, already_owned: true}
          ]} />
        </Popup>}
      {popup === "stickers" &&
        <Popup onClose={() => setPopup(undefined)}>
          <StickersPopupInner stickers={[
            {img: stickerImg, name: "Sonic Hedgehog", number: 99, already_owned: true},
            {img: stickerImg, name: "Sonic Hedgehog", number: 99, already_owned: true},
            {img: stickerImg, name: "Sonic Hedgehog", number: 99, already_owned: false},
            {img: stickerImg, name: "Sonic Hedgehog", number: 99, already_owned: true},
            {img: stickerImg, name: "Sonic Hedgehog", number: 99, already_owned: false},
            {img: stickerImg, name: "Sonic Hedgehog", number: 99, already_owned: true},
            {img: stickerImg, name: "Sonic Hedgehog", number: 99, already_owned: false},
            {img: stickerImg, name: "Sonic Hedgehog", number: 99, already_owned: true},
            {img: stickerImg, name: "Sonic Hedgehog", number: 99, already_owned: true}
          ]} />
        </Popup>}
      {popup === "single-pack" &&
        <Popup onClose={() => setPopup(undefined)}>
          <PacksPopupInner packs={{amount: 1, cover_img: coverImg, pack_img: packImg, name: "Super Collection!"}} />
        </Popup>}
      {popup === "packs" &&
        <Popup onClose={() => setPopup(undefined)}>
          <PacksPopupInner packs={{amount: 5, cover_img: coverImg, pack_img: packImg, name: "Super Collection!"}} />
        </Popup>}
    </Block>
  </>
}

const RequiresCollectionPopups: React.FC = () => {
  const [popup, setPopup] = useState<"two" | "eight">()

  return <>
    <SectionTitle>RequiresCollection popups</SectionTitle>
    <Note>
      <ul>
        <li><a onClick={() => setPopup("two")}>Two candidates</a></li>
        <li><a onClick={() => setPopup("eight")}>Eight candidates</a></li>
      </ul>
    </Note>
    <Block>
      {popup === "two" &&
        <Popup onClose={() => setPopup(undefined)}>
          <RequireCollectionPopupInner
            candidates={[
              {id: 1, name: "Super Collection", cover_img: coverImg, pack_img: packImg},
              {id: 2, name: "Another Collection With Long Name", cover_img: coverImg, pack_img: packImg},
            ] as any}
            onSelect={id => {
              alert(`Collection: ${id}`)
              setPopup(undefined)
            }}
          />
        </Popup>}
      {popup === "eight" &&
        <Popup onClose={() => setPopup(undefined)}>
          <RequireCollectionPopupInner
            candidates={[
              {id: 1, name: "Super Collection", cover_img: coverImg, pack_img: packImg},
              {id: 2, name: "Super Collection", cover_img: coverImg, pack_img: packImg},
              {id: 3, name: "Super Collection", cover_img: coverImg, pack_img: packImg},
              {id: 4, name: "Super Collection", cover_img: coverImg, pack_img: packImg},
              {id: 5, name: "Super Collection", cover_img: coverImg, pack_img: packImg},
              {id: 6, name: "Super Collection", cover_img: coverImg, pack_img: packImg},
              {id: 7, name: "Super Collection", cover_img: coverImg, pack_img: packImg},
              {id: 8, name: "Super Collection", cover_img: coverImg, pack_img: packImg},
            ] as any}
            onSelect={id => {
              alert(`Collection: ${id}`)
              setPopup(undefined)
            }}
          />
        </Popup>}
    </Block>
  </>
}

export default Sandbox
