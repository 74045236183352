import React, { PropsWithChildren } from "react"
import Button from "./Button"
import I18n from "i18n-js"

const EnterCodesForIOSButton: React.FC<PropsWithChildren<{disabled?: boolean, linkToActualEnterCodeForm?: boolean}>> = ({children, disabled, linkToActualEnterCodeForm}) => {
  const href = linkToActualEnterCodeForm
    ? "/enter_code/new"
    : "/enter_code"
  return <Button disabled={disabled} className="neo__button--enterCodes" href={href} target="_top">
    {children || I18n.t("web.pages.browser_not_supported.enter_codes_info.action_enter_codes")}
  </Button>
}

export default EnterCodesForIOSButton
