import ECommerce from "@root/ecommerce/ECommerce"
import { ECommerceState, initState } from "@root/ecommerce/state"
import { Authorization, Order, ServerErrorResp, isServerError } from "@root/ecommerce/types"
import { DedicatedOrderPage } from "@root/ecommerce/views/ViewOrder"
import React, { useContext, useState } from "react"

export type CompInit = Order | ServerErrorResp

export const OrderMount: React.FC<{init: CompInit}> = ({init}) => {
  return isServerError(init)
    ? <DedicatedOrderPage error={init} />
    : <DedicatedOrderPage order={init} />
}

export default OrderMount
