import React, {createContext, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import {
  MemoryRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
  useNavigationType,
  useNavigate,
  useSearchParams
} from "react-router-dom"
import classNames from "classnames"
import { CSSTransitionClassNames } from "react-transition-group/CSSTransition"
import { Unity, useUnityContext } from "react-unity-webgl"

import {logout as logoutFromGigya, caseByLogin as caseByGigyaLogin, showPlugin as showGigyaPlugin, request as requestViaGigya} from "../gigya"
import Browser from "@root/components/Browser"
import CompleteProfile from "@root/pages/complete_profile"

const BackLink: React.FC = () => {
  const navigate = useNavigate()
  const onClick = useCallback(() => navigate(-1), [navigate])
  return <a onClick={onClick}>Back</a>
}

const Homepage: React.FC = () => {
  const logout = useCallback((event: React.MouseEvent) => {
    event.preventDefault()
    logoutFromGigya()
  }, [])
  return (
    <div className="game__view game__view--home">
      <h2>Homepage</h2>
      <Link to="/other">To Other</Link>
      <Link to="/">To Unity</Link>
      <Link to={`/browser?url=${encodeURI("/pages/rules")}`}>To Rules</Link>
      <Link to={`/browser?url=${encodeURI("/pages/complete_profile")}`}>Complete Profile</Link>
      <a href="/session/logout" onClick={logout}>Logout</a>
    </div>
  )
}

const Other: React.FC = () => (
  <div className="game__view game__view--other">
    <h2>Other</h2>
    <BackLink />
    <Link to="/home?direction=POP">To Homepage (POP)</Link>
    <Link to="/home?direction=POP">To Homepage</Link>
    <Link to="/">To Unity</Link>
    <Link to="/?direction=POP">To Unity (POP)</Link>
  </div>
)

const UnityGame: React.FC = () => (
  <div className="game__view game__view--unityPlaceholder"/>
)

const useTransition = (push: string, pop: string) => {
  const navigationType = useNavigationType()
  const [params, _] = useSearchParams()
  const direction = params.get("direction")

  return useMemo(() => {
    const movement = direction || navigationType || "PUSH"
    return movement === "POP" ? pop : push
  }, [navigationType, direction, push, pop])
}

const UnityCanvas: React.FC<{visible: boolean}> = ({visible}) => {
  const init = useContext(InitContext)
  const [isStage0, setIsStage0] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [messages, setMessages] = useState<{target: string, method: string, payload?: string}[]>([])
  const navigate = useNavigate()

  const devicePixelRatio = useMemo(() => {
    if (window.devicePixelRatio) {
      return window.devicePixelRatio
    }
    return 1
  }, [])

  const {
    unityProvider,
    sendMessage,
    addEventListener,
    isLoaded,
    loadingProgression
  } = useUnityContext(init.unity.build)

  const onStage0Load = useCallback((): any => {
    console.log("unity", "stage0/load")
    setIsStage0(true)
  }, [setIsStage0])

  const onRoutePush = useCallback((route: any): any => {
    console.log("unity", "router/push", {route})
    if(typeof route === "string") {
      navigate(route)
    }
  }, [navigate])

  const onLogout = useCallback((): any => {
    console.log("unity", "bridge/logout")
    logoutFromGigya()
  }, [logoutFromGigya])

  const onChangeLocale = useCallback((payload: any): any => {
    const locale = payload as string
    console.log("unity", "bridge/change_locale", locale)
    location.replace("/app?locale=" + locale);
  }, [])

  const onRoutePop = useCallback((): any => {
    console.log("unity", "router/pop")
    navigate(-1)
  }, [navigate])

  const onGigyaShowPlugin = useCallback((payload: any): any => {
    console.log("unity", "gigya/show_plugin", payload)
    caseByGigyaLogin({
      loggedIn: () => {
        const parameters = {
          ...payload.data,
          onHide: (evt: any) => {
            console.log("unity", "gigya plugin hidden:", evt)
            if (evt.reason === "canceled") {
              setMessages(msgs => [...msgs, {
                target: "GigyaAuthentication",
                method: "OnShowPluginCancel",
              }])
            } else
            setMessages(msgs => [...msgs, {
              target: "GigyaAuthentication",
              method: "OnShowPluginSuccess",
            }])
          }
        }
        showGigyaPlugin(parameters)
      },
      notLoggedIn: () => {
        setMessages(msgs => [...msgs, {
          target: "GigyaAuthentication",
          method: "OnLoginRequired",
        }])
      }
    })
  }, [caseByGigyaLogin, showGigyaPlugin, setMessages])

  const onGigyaRequest = useCallback((payload: any): any => {
    console.log("unity", "gigya/request", payload)
    const parameters = {
      ...payload.data,
      callback: (evt: any) => {
        console.log("unity", "Gigya request finished:", evt)
        if (evt.errorCode === 0) {
          setMessages(msgs => [...msgs, {
            target: "GigyaAuthentication",
            method: "OnRequestSuccess",
            payload: JSON.stringify(evt),
          }])
        } else {
          setMessages(msgs => [...msgs, {
            target: "GigyaAuthentication",
            method: "OnRequestError",
            payload: evt.errorMessage,
          }])
        }
      }
    }
    requestViaGigya(payload.request, parameters)
  }, [requestViaGigya, setMessages])

  useEffect(() => {
    if(!isStage0) { return }
    console.log("unity", "stage0/loaded", init.unity.stage0)
    sendMessage("_WebGLStage0Loader", "OnLoaded", JSON.stringify(init.unity.stage0))
    setIsReady(true)
  }, [isStage0, sendMessage, init, setIsReady])

  useEffect(() => {
    if(isReady && visible) {
      sendMessage("_WebGLRouter", "OnReturned")
    }
  }, [isReady, visible, sendMessage])

  // this is needed due to some limitation of react-unity-webgl
  useEffect(() => {
    if(messages.length === 0) return
    messages.forEach(msg => {
      console.log("Unity", "sendMessage", msg)
      sendMessage(msg.target, msg.method, msg.payload)
    })
    setMessages([])
  }, [JSON.stringify(messages), sendMessage, setMessages])

  useEffect(() => {
    console.log("Unity", "config:", unityProvider.unityConfig)

    addEventListener("stage0/load", onStage0Load)
    addEventListener("router/push", onRoutePush)
    addEventListener("stage0/pop", onRoutePop)
    addEventListener("bridge/logout", onLogout)
    addEventListener("bridge/change_locale", onChangeLocale)
    addEventListener("gigya/show_plugin", onGigyaShowPlugin)
    addEventListener("gigya/request", onGigyaRequest)
    return () => {
      removeEventListener("stage0/load", onStage0Load)
      removeEventListener("router/push", onRoutePush)
      removeEventListener("stage0/pop", onRoutePop)
      removeEventListener("bridge/logout", onLogout)
      removeEventListener("bridge/change_locale", onChangeLocale)
      removeEventListener("gigya/show_plugin", onGigyaShowPlugin)
      removeEventListener("gigya/request", onGigyaRequest)
    }
  }, [addEventListener, removeEventListener, onStage0Load, onRoutePush, onRoutePop, onLogout, onChangeLocale, onGigyaShowPlugin, onGigyaRequest])

  return (
    <div className="unity__container">
      {
        !isLoaded &&
          <div className="unity__loadingOverlay">
            <p className="unity__loadingOverlay__percentage">{Math.round(loadingProgression * 100)}%</p>
          </div>
      }
      <Unity unityProvider={unityProvider} className="unity__instance" devicePixelRatio={devicePixelRatio}/>
    </div>
  )
}

const RealUnity: React.FC = () => {
  const nodeRef = useRef(null)
  const location = useLocation()
  const [isIn, setIsIn] = useState(false)
  const [isEntered, setIsEntered] = useState(false)
  const [hidden, setHidden] = useState(false)

  const transition = useTransition("slideHorizontal", "slideHorizontal--reverse")
  useEffect(() => {
    setIsIn(location.pathname === "/")
    if(location.pathname === "/") setHidden(false)
  }, [location.pathname, setIsIn])

  const onEntered = useCallback(() => {
    setIsEntered(true)
  }, [setIsEntered])

  const onExited = useCallback(() => {
    setHidden(true)
  }, [setHidden])

  const className = classNames(
    "game__view",
    "game__view--unity",
    hidden ? "game__view--unity--hidden" : "game__view--unity--active"
  )

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={isIn}
      timeout={2000}
      classNames={transition}
      onEntered={onEntered}
      onExited={onExited}
      appear
      mountOnEnter
    >
      <div ref={nodeRef} className={className}>
        {isEntered && <UnityCanvas visible={isEntered && isIn}/>}
        {/* <BackLink />
        <Link to="/">To Homepage</Link>
        <Link to="/other">To Other</Link> */}
      </div>
    </CSSTransition>
  )
}

const useExposeNavigationToConsole = () => {
  const navigate = useNavigate()
  ;(window as any).__navigation = {
    navigate,
    toRules: () => { navigate(`/browser?url=${encodeURI("/pages/rules")}`) },
    toBrowserNotSupported: () => { navigate(`/browser?url=${encodeURI("/pages/browser_not_supported")}`) },
    toOrders: () => { navigate(`/browser?url=${encodeURI("/orders")}`) },
    toCompleteProfile: () => { navigate(`/complete_profile`) }
  }
}

const detectEmbedding = (() => {
  let _embedding: "ownPage" | "inIFrame"

  return () => {
    if (!_embedding) {
      _embedding = window.parent === window
        ? "ownPage"
        : "inIFrame"
    }
    return _embedding
  }
})()

export const useAppNavigation = () => {
  const navigate = (url: string) => {
    if (detectEmbedding() === "inIFrame") {
      window.parent.postMessage(navigationPostMessagePayload(`/browser?url=${encodeURIComponent(url)}`))
    } else if (detectEmbedding() === "ownPage") {
      window.location.href = url
    }
  }

  return {
    navigate
  }
}

export const navigationPostMessagePayload = (destination: string) => {
  return {
    type: "neo__navigate",
    destination
  }
}

const useExposeNavigationToPostMessage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const onMessage = (evt: MessageEvent) => {
      if (evt.data?.type === "neo__navigate") {
        navigate(evt.data.destination)
      }
    }
    window.addEventListener("message", onMessage)

    return () => { // cleanup
      window.removeEventListener("message", onMessage)
    }
  }, [])
}

const dynamicChildFactory = (classNames: string | CSSTransitionClassNames) => (child: React.ReactElement) => React.cloneElement(child, {classNames})

const Navigation: React.FC = () => {
  const location = useLocation()
  const transition = useTransition("slideHorizontal", "slideHorizontal--reverse")

  useExposeNavigationToPostMessage()
  useExposeNavigationToConsole()

  return <div className="game__viewContainer">
    <RealUnity />
    <TransitionGroup component={null} childFactory={dynamicChildFactory(transition)}>
      <CSSTransition key={location.key} classNames={transition} timeout={2000} >
        <Routes location={location}>
          <Route path="/" element={<UnityGame/>} />
          <Route path="/browser" element={<Browser/>} />
          <Route path="/complete_profile" element={<Browser titleKey="/pages/complete_profile"><CompleteProfile /></Browser>} />
          <Route path="/other" element={<Other/>} />
          <Route path="/home" element={<Homepage/>} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  </div>
}

interface IInit {
  initialEntries?: string[]
  unity: {
    stage0: {
      browser_user_agent: string
    },
    build: {
      loaderUrl: string
      dataUrl: string
      frameworkUrl: string
      codeUrl: string
    }
  }
}

const InitContext = createContext<IInit>(null)

const Game: React.FC<{init: IInit}> = ({init}) => {
  return (
    <InitContext.Provider value={init}>
      <div className="game">
        <Router initialEntries={init.initialEntries}>
          <Navigation />
        </Router>
      </div>
    </InitContext.Provider>
  )
}

export default Game
