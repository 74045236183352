import _ from "lodash"
import { Reducer } from "react"
import { ECommerceActions } from "../actions"
import { ECommerceState } from "../state"
import { isServerError } from "../types"

const paymentReducer = (state: ECommerceState, action: ECommerceActions) => {
  switch (action.type) {
    case "payment__setCheckoutButtonDisabled":
      return {
        ...state,
        paymentButtonDisabled: action.data.disabled
      } as ECommerceState
  }
  return state
}

export default paymentReducer
