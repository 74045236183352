import { spawn, take } from "redux-saga/effects"
import { all, put, takeEvery, getContext, race, select } from "redux-saga/effects"
import { ActionForType, ECommerceActionType, ECommerceActions } from "../actions"
import { JSONPostApiResp, JSONPostApiSagaContext, JSON_POST_API_CONTEXT_KEY } from "@root/misc/jsonPostApi"
import _ from "lodash"
import { ServerErrorResp, isServerError } from "../types"
import { ECommerceState } from "../state"
import {apiPath} from "../ecommerceRoutes"
import { actionTypes } from "../helper"
import I18n from "i18n-js"
import { ApiPaymentHandleAdyenSubmit, ApiPaymentInit, ApiReq, ApiResp } from "../actions/serverApiActions"
import { END, eventChannel } from "redux-saga"

const handleAdyenSubmit = function*(action: ActionForType<"payment__handleAdyenSubmit">) {
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/payment_handle_adyen_submit"),
    data: {
      billing_address: action.data.billingAddress,
      shipping_address: action.data.shippingAddress,
      adyen_data: action.data.adyenData
    } as ApiReq<ApiPaymentHandleAdyenSubmit>
  }})
  const resp: ECommerceActions = yield take<ECommerceActions>(actionTypes(
    "api__request__error",
    "api__receive__ecommerce/payment_handle_adyen_submit"))
  
  if (resp.type === "api__request__error") {
    yield put<ECommerceActions>({type: "payment__handleAdyenError", data: {error: {message: I18n.t("ecommerce.pay.error_during_checkout")}}})
  } else if (resp.type === "api__receive__ecommerce/payment_handle_adyen_submit") {
    if (isServerError(resp.data)) {
      yield put<ECommerceActions>({type: "payment__handleAdyenError", data: {error: {message: resp.data.error.description}}})
    } else {
      console.log("Response", resp.data)
      const adyenResp = JSON.parse(resp.data.adyen_response)
      const orderId = resp.data.order_id // we don't use a session for this yet
      if(adyenResp.action) {
        // Drop-in handles the action object from the response
        action.data.dropin.handleAction(adyenResp.action)
      } else {
        if (adyenResp.isFinal) {
          // Payment successful
          console.log("YEAH!")
          yield put<ECommerceActions>({type: "payment__handleAdyenIsFinished", data: {orderId}})
        } else { // something was not working
          yield put<ECommerceActions>({type: "payment__handleAdyenError", data: {error: {message: I18n.t("ecommerce.pay.error_during_checkout")}}})
        }
      }
    }
  }
}

const handleAdyenAdditionalDetails = function*(action: ActionForType<"payment__handleAdyenAdditionalDetails">) {
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/payment_handle_adyen_additional_details"),
    data: {
      billing_address: action.data.billingAddress,
      shipping_address: action.data.shippingAddress,
      adyen_data: action.data.adyenData
    } as ApiReq<ApiPaymentHandleAdyenSubmit>
  }})
  const resp: ECommerceActions = yield take<ECommerceActions>(actionTypes(
    "api__request__error",
    "api__receive__ecommerce/payment_handle_adyen_additional_details"))
  
  if (resp.type === "api__request__error") {
    yield put<ECommerceActions>({type: "payment__handleAdyenError", data: {error: {message: I18n.t("ecommerce.pay.error_during_checkout")}}})
  } else if (resp.type === "api__receive__ecommerce/payment_handle_adyen_additional_details") {
    if (isServerError(resp.data)) {
      yield put<ECommerceActions>({type: "payment__handleAdyenError", data: {error: {message: resp.data.error.description}}})
    } else {
      console.log("Response", resp.data)
      const adyenResp = JSON.parse(resp.data.adyen_response)
      const orderId = resp.data.order_id // we don't use a session for this yet
      if(adyenResp.action) {
        // Drop-in handles the action object from the response
        action.data.dropin.handleAction(adyenResp.action)
      } else {
        if (adyenResp.isFinal) {
          // Payment successful
          console.log("YEAH!")
          yield put<ECommerceActions>({type: "payment__handleAdyenIsFinished", data: {orderId}})
        } else { // something was not working
          yield put<ECommerceActions>({type: "payment__handleAdyenError", data: {error: {message: I18n.t("ecommerce.pay.error_during_checkout")}}})
        }
      }
    }
  }
}

const start = function*(action: ActionForType<"payment__start">) {
  yield put<ECommerceActions>({type: "payment__setCheckoutButtonDisabled", data: { disabled: true }})
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/payment_init"),
    data: {
      billing_address: action.data.billingAddress,
      shipping_address: action.data.shippingAddress,
      payment_type: "adyen_cc"
    } as ApiReq<ApiPaymentInit>
  }})
  const resp: ECommerceActions = yield take<ECommerceActions>(actionTypes("api__request__error", "api__receive__ecommerce/payment_init"))
  yield put<ECommerceActions>({type: "busy__setNotBusy"})

  if (resp.type === "api__request__error") {
  } else if (resp.type === "api__receive__ecommerce/payment_init") {
    const adyenApi: any = (window as any).AdyenCheckout
    if (isServerError(resp.data)) {
      yield put<ECommerceActions>({type: "displayError__set", data: {error: resp.data.error.description}})
    } else if (!adyenApi) {
      yield put<ECommerceActions>({type: "displayError__set", data: {error: I18n.t("ecommerce.pay.error_no_adyen_sdk")}})
    } else {
      const respData: ApiResp<ApiPaymentInit> = resp.data
      if (!document.getElementById("__adyen-dropin-container")) {
        // add dropin container into DOM
        const dropinContainer = document.createElement("div")
        dropinContainer.setAttribute("id", "__adyen-dropin-container")
        document.body.prepend(dropinContainer)
      }

      let _emitter: (data: ECommerceActions | END) => void

      const channel = eventChannel<ECommerceActions>((emitter) => {
        _emitter = emitter
        return () => {}
      })

      yield put<ECommerceActions>({type: "navigation__scrollToTop"})

      adyenApi({
        ...respData.adyen_config,
        onSubmit: (state: any, dropin: any) => {
          console.log("adyen submit callback", state)
          _emitter({type: "payment__handleAdyenSubmit", data: {
            adyenData: JSON.stringify(state.data),
            dropin,
            billingAddress: action.data.billingAddress,
            shippingAddress: action.data.shippingAddress
          }})
        },
        onAdditionalDetails: (state: any, dropin: any) => {
          console.log("additionalDetails", state)
          _emitter({type: "payment__handleAdyenAdditionalDetails", data: {
            adyenData: JSON.stringify(state.data),
            dropin,
            billingAddress: action.data.billingAddress,
            shippingAddress: action.data.shippingAddress
          }})
        },
        onError: (error: any, component: any) => {
          console.error(error.name, error.message, error.stack, component)
          _emitter({type: "payment__handleAdyenError", data: {error}})
        },
      }).then((checkout: any) => {
        const dropin = checkout
          .create('dropin', {
            openFirstPaymentMethod: true,
            openFirstStoredPaymentMethod: false,
            showStoredPaymentMethods: false,
          })
          .mount('#__adyen-dropin-container');
      })

      while(true) {
        const result: {channel?: any, payment?: any} = yield race<any>({
          channel: take(channel),
          payment: take(["payment__handleAdyenIsFinished", "payment__handleAdyenError"]),
        })
        const took: END | ECommerceActions = result.channel || result.payment
        if (took.type === "@@redux-saga/CHANNEL_END"
          || took.type === "payment__handleAdyenIsFinished"
          || took.type === "payment__handleAdyenError"
        ) {
          if (took.type === "payment__handleAdyenError") {
            yield put<ECommerceActions>({type: "displayError__set", data: {error: took.data.error.message}})
          }
          break
        } else {
          yield put<ECommerceActions>(took)
        }
      }
      _emitter(END)

      const dropinContainer = document.getElementById("__adyen-dropin-container")
      dropinContainer?.remove()
    }
  }
  yield put<ECommerceActions>({type: "payment__setCheckoutButtonDisabled", data: { disabled: false }})
}

const handleAdyenIsFinished = function*(action: ActionForType<"payment__handleAdyenIsFinished">) {
  yield put<ECommerceActions>({type: "navigation__set", data: { navigation: {page: "order", orderId: action.data.orderId}}})
}

export function* paymentSaga() {
  yield all([
    takeEvery<ECommerceActionType>("payment__start", start),
    takeEvery<ECommerceActionType>("payment__handleAdyenSubmit", handleAdyenSubmit),
    takeEvery<ECommerceActionType>("payment__handleAdyenAdditionalDetails", handleAdyenAdditionalDetails),
    takeEvery<ECommerceActionType>("payment__handleAdyenIsFinished", handleAdyenIsFinished),
  ])
}

export default paymentSaga
