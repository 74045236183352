const SCHEMA = "uniwebview://"
const SEL_EXTERNAL_LINK = "a[rel='external']"

declare global {
  var UniWebView: Webview.Instance
}

export function setup() {
  delegate("click", SEL_EXTERNAL_LINK, (el, event) => {
    if (window.UniWebView && window.UniWebView.isWebView) {
      const url = el.getAttribute("href")
      if (url) {
        event.preventDefault()
        event.stopImmediatePropagation()
        openURL(url)
      }
    }
  })
}

function openURL(url: string) {
  _callWebview("open-url", {
    url: encodeURIComponent(url)
  })
}

function _callWebview(path: string, parameters: Record<string, string> = null) {
  // We need to wait for the document to be ready before changing the location's href
  onDocumentReady(() => {
    const params = new URLSearchParams(parameters || {})
    const url = `${SCHEMA}${path}?${params.toString()}`
    console.log("webview call:", url)
    window.location.href = url
  })
};

function onDocumentReady(cb: () => void) {
  if (document.readyState !== "loading") {
    cb()
  } else {
    document.addEventListener("DOMContentLoaded", cb)
  }
}

function delegate<K extends keyof DocumentEventMap>(eventName: string, selector: string, fn: (el: HTMLElement, evt: DocumentEventMap[K]) => any) {
  document.addEventListener(eventName, (event: DocumentEventMap[K]) => {
    const el = event.target as HTMLElement
    if (el.closest(selector)) {
      fn(el, event)
    }
  })
}