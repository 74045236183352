import React, { useEffect } from "react"
import _ from "lodash"
import { useECommerce } from "../context"
import I18n from "i18n-js"
import { AddressScope } from "../types"
import { ActionLinkHolder, ActionsHolder, ConfirmAction, Info, ItemList, ItemListEmptyNote, LoadingPlaceholder, PageWrapper, Row, ShortAddress, Title } from "../Ui"
import Button from "@root/components/Button"

const ViewAddresses: React.FC<{addressScope: AddressScope, needsShipping: boolean}> = ({addressScope, needsShipping}) => {
  const {state, dispatch} = useECommerce()  

  useEffect(() => {
    dispatch({type: "ecommerce__getAddresses", data: {addressScope}})
  }, [])

  if (!state.addresses) {
    return <LoadingPlaceholder />
  }

  return <PageWrapper>
    <Row>
      <Title>{I18n.t(`ecommerce.address.title--${addressScope}`)}</Title>
    </Row>
    <Row>
      <Info>{I18n.t(`ecommerce.address.info--${addressScope}`)}</Info>
    </Row>
    <Row>
      <ItemList>
        {state.addresses.entries.length === 0 &&
          <ItemListEmptyNote>{I18n.t("ecommerce.address.no_addresses_yet_info")}</ItemListEmptyNote>}
        {_.map(state.addresses.entries, (address) => {
          return <ShortAddress
            key={address.id}
            address={address}
            actionArea={<>
              <Button onClick={() => dispatch({type: "ecommerce__selectAddress", data: {addressScope, addressId: address.id, needsShipping}})}>
                {I18n.t("ecommerce.address.action_select")}
              </Button>
              <a onClick={() => dispatch({type: "ecommerce__toCreateOrEditAddress", data: {addressScope, address, needsShipping}})}>
                {I18n.t("ecommerce.address.action_edit_address")}
              </a>
            </>} />
        })}
      </ItemList>
    </Row>
    <Row>
      <ActionsHolder>
        <Button onClick={() => dispatch({type: "ecommerce__toCreateOrEditAddress", data: {addressScope, needsShipping}})}>
          {I18n.t("ecommerce.address.action_enter_new")}
        </Button>
        <ConfirmAction
          actionLabel={I18n.t("ecommerce.address.action_abort")}
          action={() => dispatch({type: "navigation__set", data: {navigation: {page: "landing"}}})}
          confirmQuestion={I18n.t("ecommerce.address.confirm_abort")}
          confirmYes={I18n.t("ecommerce.address.confirm_abort_yes")}
          confirmNo={I18n.t("ecommerce.address.confirm_abort_no")} />
      </ActionsHolder>
    </Row>
  </PageWrapper>
}

export default ViewAddresses
