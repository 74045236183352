import React from "react"

import { PageTitle, SectionTitle, Block, Note } from '../../pages/sandbox'
import _ from "lodash"
import { initState } from "../state"
import { MockContextProvider } from "../context"
import ViewSelectShipping from "./ViewSelectShipping"

const Sandbox:React.FC = () => {
  return <>
    <Block>
      <Note>3 options</Note>
      <MockContextProvider state={{...initState,
        shippingOptions: [
          {carrier_code: "sandbox_carr1", method_code: "sandbox_meth1", price_value: "4,99€", title: "DHL"},
          {carrier_code: "sandbox_carr2", method_code: "sandbox_meth2", price_value: "94,99€", title: "FedEx"},
          {carrier_code: "sandbox_carr3", method_code: "sandbox_meth3", price_value: "1,99€", title: "Pidgeon"}
        ]
      }}>
        <ViewSelectShipping />
      </MockContextProvider>
    </Block>

    <Block>
      <Note>1 option</Note>
      <MockContextProvider state={{...initState,
        shippingOptions: [
          {carrier_code: "sandbox_carr1", method_code: "sandbox_meth1", price_value: "4,99€", title: "DHL"}
        ]
      }}>
        <ViewSelectShipping />
      </MockContextProvider>
    </Block>
  </>
}

export default ["ec/SelectShipping", Sandbox]
