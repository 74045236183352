import I18n from "i18n-js"
import React, { PropsWithChildren, useCallback } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

const Browser: React.FC<PropsWithChildren<{titleKey?: string}>> = ({children, titleKey}) => {
  const [params] = useSearchParams()
  const url = params.get("url")
  const title = params.get("title") || I18n.t(`web.pages.browser_title.${titleKey || url}`, {defaultValue: I18n.t("web.pages.browser_title.fallback")})

  const navigate = useNavigate()
  const goBack = useCallback(() => navigate(-1), [navigate])

  return (
    <div className="game__view game__view--browser">
      <div className="browser">
        <div className="browser__header">
          <div className="browser__header__actionArea">
            <a className="browser__backLink" onClick={goBack}><span>{I18n.t("web.pages.browser_back_link")}</span></a>
          </div>
          <div className="browser__header__titleArea">
            <h2>{title}</h2>
          </div>
          <div className="browser__header__actionArea" />
        </div>
        {children ||
          <div className="browser__frameHolder">
            <iframe className="browser__frame" src={url}/>
          </div>}
      </div>
    </div>
  )
}

export default Browser
