import React from "react"

import { AppLinks } from "@root/types"
import SectionTitle from "@root/components/SectionTitle"
import I18n from "i18n-js"
import _ from "lodash"
import EnterCodesForIOSButton from "@root/components/EnterCodesForIOSButton"
import {openLogin} from "../gigya"
import Button from "@root/components/Button"
import iosStoreImg from "@images/download__ios.png"
import LogoutButton, { LogoutButtonHolder } from "@root/components/LogoutButton"

export interface CompInit {
  applinks: AppLinks
  logged_in: boolean
}

const b = (key: string) => `web.pages.enter_code.explanation.${key}`

const StepTitle: React.FC<{step: number}> = ({step}) => <h2><span>{I18n.t(b(`step${step}.step`))}</span>{" "}{I18n.t(b(`step${step}.title`))}</h2>

const EnterCode: React.FC<{init: CompInit}> = ({init}) => {
  return <div className="neo__enterCodeExpl">
    <div className="neo__enterCodeExpl__inner">

      <div className="neo__enterCodeExpl__contentRow">
        <SectionTitle>{I18n.t(b("title"))}</SectionTitle>
      </div>

      <div className="neo__enterCodeExpl__contentRow">
        <div className="neo__enterCodeExpl__steps">
          <div className="neo__enterCodeExpl__step">
            <StepTitle step={1} />
            <p>{I18n.t(b(`step1.info`))}</p>
            <div className="neo__enterCodeExpl__iosLinkHolder">
              <a href={init.applinks.ios} target="_blank"><img src={iosStoreImg} /></a>
            </div>
          </div>
          <div className="neo__enterCodeExpl__step">
            <StepTitle step={2} />
            <p>{I18n.t(b(`step2.info`))}</p>
          </div>
          <div className="neo__enterCodeExpl__step">
            <StepTitle step={3} />
            <p>{I18n.t(b(`step3.info`))}</p>
            <div className="neo__enterCodeExpl__step__loginHolder">
              {init.logged_in
                ? <div className="neo__enterCodeExpl__step__loggedInInfo">{I18n.t(b("step3.info_logged_in"))}</div>
                : <Button onClick={() => openLogin("enterCode")}>{I18n.t(b("step3.action_login"))}</Button>}
            </div>
          </div>
          <div className="neo__enterCodeExpl__step">
            <StepTitle step={4} />
            <p>{I18n.t(b(`step4.info`))}</p>
            <div className="neo__enterCodeExpl__step__codeButtonHolder">
              <EnterCodesForIOSButton linkToActualEnterCodeForm disabled={!init.logged_in}>{I18n.t(b("step4.action_enter_codes"))}</EnterCodesForIOSButton>
              {!init.logged_in &&
                <div className="neo__enterCodeExpl__step__notLoggedInInfo">
                  {I18n.t(b("step4.info_not_logged_in"))}
                </div>}
            </div>
          </div>
        </div>
      </div>
      {init.logged_in && <LogoutButtonHolder><LogoutButton /></LogoutButtonHolder>}
    </div>
  </div>
}

export default EnterCode
