import { ECommerceActionType } from "./actions"

export const API_BASE = "/api/v1"

export const apiPath = (actionType: ECommerceActionType) => {
  if (actionType.indexOf("api__receive__") === 0) {
    return `${API_BASE}/${actionType.substring(14)}`
  }
  throw new Error(`actionType ${actionType} does not belong to an api response`)
}
