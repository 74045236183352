import React, { useEffect } from "react"
import { useECommerce } from "../context"
import I18n from "i18n-js"

const DisplayErrorOverlay: React.FC = () => {
  const {state, dispatch} = useECommerce()

  const handleError = () => {
    if (!state.error) { return }
    if (state.error.requiresReload) {
      window.location.reload()
    } else {
      dispatch({type: "displayError__clear"})
    }
  }

  return state.error
    ? <div className="neo__ec__displayError__overlay">
        <div>
          <p>{state.error.error}</p>
          <p>
            <a onClick={() => handleError()}>{I18n.t("ecommerce.display_error.action_ok")}</a>
          </p>
        </div>
      </div>
    : undefined
}

export default DisplayErrorOverlay
