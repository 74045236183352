import React, { useEffect, useState } from "react"
import _ from "lodash"
import I18n from "i18n-js"
import { Info, ItemList, PageWrapper, Pagination, Row } from "../Ui"
import { navigationPostMessagePayload, useAppNavigation } from "@root/pages/game"
import { PageInfo } from "../types"
import { useApiPost } from "@root/misc/jsonPostApi"
import { API_BASE } from "../ecommerceRoutes"

export interface OrdersListEntry {
  order_id: number
  order_number: string
  description: string
  ordered_at: string
  url?: string,
}

export const DedicatedViewOrders: React.FC<{orders: OrdersListEntry[], pageInfo: PageInfo}> = ({orders: initialOrders, pageInfo: initialPageInfo}) => {
  const { navigate } = useAppNavigation()
  const { apiPost } = useApiPost()
  const [orders, setOrders] = useState(initialOrders)
  const [pageInfo, setPageInfo] = useState(initialPageInfo)

  const changePage = (page: number) => {
    apiPost<PageInfo & {orders: OrdersListEntry[]}>(`${API_BASE}/ecommerce/get_orders`, {page})
      .then((resp) => {
        if (resp?.data?.[0]?.orders) {
          setOrders(resp.data[0].orders)
          setPageInfo(resp.data[0])
          window.scrollTo({top: 0})
        }
      })
      .catch((err) => { console.log("error on requesting orders", err)})
  }

  return <PageWrapper>
    <Row>
      <Info>{I18n.t("ecommerce.orders.info")}</Info>
    </Row>
    {orders.length > 0
      ? <>
          <Row>
            <ItemList>
              {_.map(orders, (o) => {
                return <div className="neo__ec__orders__entry" key={o.order_id}>
                  <div>
                    <p className="neo__ec__orders__description">{o.description}</p>
                    <h3>{o.order_number}</h3>
                    <p className="neo__ec__orders__date">{I18n.t("ecommerce.orders.ordered_at", {date: o.ordered_at})}</p>
                  </div>
                  <div>
                    <a onClick={() => navigate(o.url)}>
                      {I18n.t("ecommerce.orders.action_view_details")}
                    </a>
                  </div>
                </div>
              })}
            </ItemList>
          </Row>
          {pageInfo.total_pages > 1 &&
            <Pagination pageInfo={pageInfo} onChange={changePage} />}
        </>
      : <Row>
          <ItemList>
            <div className="neo__ec__orders__none">
              {I18n.t("ecommerce.orders.no_orders")}
            </div>
          </ItemList>
        </Row>}
  </PageWrapper>
}
export default DedicatedViewOrders
