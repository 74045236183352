import React from "react"
import I18n from "i18n-js"

import SectionTitle from "@root/components/SectionTitle"
import _ from "lodash"
const b = (key: string) => `web.pages.rules.${key}`

export default () => {
  return <div className="neo__rules">
    <div className="neo__rules__inner">
      <div className="neo__rules__contentRow">
        <SectionTitle>{I18n.t(b("title"))}</SectionTitle>
      </div>
      <div className="neo__rules__contentRow">
        <div className="neo__rules__textHolder">
          <p>{_.times(60, () => "Todo ")}</p>
          <p>{_.times(100, () => "Todo ")}</p>
          <p>{_.times(70, () => "Todo ")}</p>
          <p>{_.times(30, () => "Todo ")}</p>
        </div>
      </div>
    </div>
  </div>
}
