export type CustomerAccessToken = string

export type CollectionID = number

export interface WhichShop {
  type: "coins" | "collection"
  collection?: CollectionID
}

export interface Authorization {
  access_token: string
  token_type: string
}

export interface PageInfo {
  page: number
  total_pages: number
}

export type Sku = string

export interface ShopItemImage {
  thumb: string
  large: string
}

export type PriceValue = string

export type Price = {
  value: PriceValue
  subtitle?: string
}

export type ItemConfigurationType = "select_stickers"

export interface ScopeInfoFromServer {
  customer_access_token?: CustomerAccessToken,
  transaction_id?: number
}

export const isServerError = (resp: any): resp is ServerErrorResp => {
  return "error" in resp
}

export interface ServerErrorResp {
  error: {
    type: string
    description: string
  }
}

export interface ShopItem {
  sku: Sku
  name: string
  description?: string
  image?: ShopItemImage
  price: Price
  needs_shipping?: boolean
  out_of_stock?: boolean
  coming_soon?: boolean

  needs_configuration?: boolean
  configuration_type?: ItemConfigurationType
}

export type StickerId = number

export interface SelectStickersItem {
  image: string
  name: string
  number: number
  sticker_id: StickerId
}

export interface DisplayError {
  error: string
  requiresReload?: boolean
}

export interface CartEntry {
  item: ShopItem
  selected_stickers?: {sticker: SelectStickersItem, amount?: number}[]
  amount_info: {
    amount: number
    total_price: Price,
    can_change: boolean
    max?: number
  }
}

export type AmountInfo = CartEntry["amount_info"]

export interface ShopItemConfiguration {
  selected_stickers?: {sticker_id: StickerId, amount?: number}[]
}

export type ItemConfigurationInfo =
  {
    restricted_to_countries?: string[]
  } & (
    | {
      select_stickers: {
        collection_name: string
        stickers: SelectStickersItem[]
      }
    }
  )

export interface Cart {
  needs_shipping?: boolean
  entries: CartEntry[]
  restricted_to_countries?: string[]
}

export interface AddressCountry {
  uid: string
  name: string
}

export interface AddressRegion {
  id: number
  name: string
}

export interface ShippingOption {
  carrier_code: string
  method_code: string
  title: string
  price_value: PriceValue
}

export interface CostSegment {
  title: string
  value: PriceValue
}

export interface PurchaseSummary {
  entries: CartEntry[]
  billing_address: Address
  shipping_address?: Address
  coupon_code?: {         // when used
    code: string,
    discount: PriceValue
  }
  cost_segments: CostSegment[]
  payment_options: {name: string, type: PaymentType}[]
}

export type AddressScope = "billing" | "shipping"

export interface Address {
  id: number
  firstname: string
  lastname: string
  country: AddressCountry
  region?: AddressRegion
  street: string[]
  postcode: string
  city: string
  telephone: string
}

export type PaymentType = "adyen_cc"

export interface Order {
  order_id: number
  total_cost?: PriceValue
  order_number: string
  order_status: string
  payment_method: string
  billing_address: Address
  shipping_address?: Address
  shipping_method?: string
  entries: CartEntry[]
}

export type Navigation =
    {page: "landing"}
  | {page: "cart"}
  | {page: "selectStickers", item: ShopItem}
  | {page: "addresses", addressScope: AddressScope, needsShipping: boolean}
  | {page: "createOrEditAddress", addressScope: AddressScope, address?: Address, needsShipping: boolean, suggestedCountryUid?: string }
  | {page: "selectShipping"}
  | {page: "summary"}
  | {page: "order", orderId: number}
