import React from "react"

import { PageTitle, SectionTitle, Block, Note } from '../../pages/sandbox'
import _ from "lodash"
import { initState } from "../state"
import { MockContextProvider } from "../context"
import { ShopItemImage } from "../types"
import ViewCreateOrEditAddress from "./ViewCreateOrEditAddress"

const itemImg = (src: string): ShopItemImage => {return {large: src, thumb: src}}

const Sandbox:React.FC = () => {
  return <>
    <Block>
      <Note>Create</Note>
      <MockContextProvider state={{...initState}}>
        <ViewCreateOrEditAddress backTo="billing" needsShipping/>
      </MockContextProvider>
    </Block>

    <Block>
      <Note>Create, countries and regions loaded, try to prefill country</Note>
      <MockContextProvider state={{...initState,
        createOrEditAddress: {
          countryOptions: {
            regions: [{id: 99999999, name: "NRW"}, {id: 888888888, name: "Auenland"}],
            streetLabels: ["Street", "Kellergeschoss"]
          },
          availableCountries: [{name: "Germany", uid: "GER"}, {name: "Mittelerde", uid: "MID"}, {name: "Green Hill Zone", uid: "GHLL"}]
        }
      }}>
        <ViewCreateOrEditAddress backTo="billing" needsShipping suggestedCountryUid="GER"/>
      </MockContextProvider>
    </Block>

    <Block>
      <Note>No regions for this country, should use a regular input</Note>
      <MockContextProvider state={{...initState,
        createOrEditAddress: {
          countryOptions: {
            regions: [],
            streetLabels: ["Street"]
          },
          availableCountries: [{name: "Germany", uid: "GER"}, {name: "Mittelerde", uid: "MID"}, {name: "Green Hill Zone", uid: "GHLL"}]
        }
      }}>
        <ViewCreateOrEditAddress backTo="billing" needsShipping suggestedCountryUid="GER"/>
      </MockContextProvider>
    </Block>

    <Block>
      <Note>No available countries (while loading) - should also disable button</Note>
      <MockContextProvider state={{...initState,
        createOrEditAddress: {
        }
      }}>
        <ViewCreateOrEditAddress backTo="billing" needsShipping suggestedCountryUid="GER"/>
      </MockContextProvider>
    </Block>

    <Block>
      <Note>No country options (while loading) - should also disable button</Note>
      <MockContextProvider state={{...initState,
        createOrEditAddress: {
          availableCountries: [{name: "Germany", uid: "GER"}, {name: "Mittelerde", uid: "MID"}, {name: "Green Hill Zone", uid: "GHLL"}]
        }
      }}>
        <ViewCreateOrEditAddress backTo="billing" needsShipping suggestedCountryUid="GER"/>
      </MockContextProvider>
    </Block>

    <Block>
      <Note>Create, errors on all fields</Note>
      <MockContextProvider state={{...initState, createOrEditAddress: {
        error: "Please fill out everything!",
        errorFields: ["city", "country", "firstname", "lastname", "postcode", "region", "street", "telephone"]
      }}}>
        <ViewCreateOrEditAddress backTo="billing" needsShipping/>
      </MockContextProvider>
    </Block>

    <Block>
      <Note>Edit</Note>
      <MockContextProvider state={{...initState}}>
        <ViewCreateOrEditAddress needsShipping address={{
          id: 999999999,
          country: { name: "Germany", uid: "sandbox__germany"},
          city: "Green Hills",
          firstname: "Sonic",
          lastname: "Hedgehog",
          postcode: "1111",
          street: ["Greenhill Zone 1"],
          region: {name: "Hill", id: 999999999},
          telephone: "234567890"
        }} backTo="billing" />
      </MockContextProvider>
    </Block>
  </>
}

export default ["ec/AddressForm", Sandbox]
