import React, { useEffect } from "react"
import I18n from "i18n-js"

import {overwriteEventHandlers} from "../gigya"

import Button from "@root/components/Button"
import SectionTitle from "@root/components/SectionTitle"

const b = (key: string) => `web.pages.account_activated.${key}`

export default () => {
  useEffect(() => {
    overwriteEventHandlers({
      onLogin: () => {
        // noop
      },
      onLogout: () => {
        // noop
      }
    })
    return () => {
      overwriteEventHandlers({})
    }
  }, [])
  return <div className="neo__accountActivated">
    <div className="neo__accountActivated__inner">
      <SectionTitle>{I18n.t(b("title"))}</SectionTitle>
      <p>{I18n.t(b("info"))}</p>
      <p>{I18n.t(b("continue"))}</p>
      <Button href="/">{I18n.t(b("btn_continue"))}</Button>
    </div>
  </div>
}
