import { Address, AddressCountry, AddressRegion, AddressScope, Authorization, Cart, DisplayError, ItemConfigurationInfo, Navigation, Order, PurchaseSummary, ScopeInfoFromServer, ShippingOption, ShopItem, WhichShop } from "./types";
import I18n from "i18n-js";

export interface ECommerceState {
  formAuthToken: string
  navigation: Navigation
  authorization: Authorization
  locale: string
  shop: WhichShop
  busy: boolean
  error?: DisplayError
  cart?: Cart
  itemConfigurationInfo?: ItemConfigurationInfo
  availableItems: ShopItem[]
  addresses?: {
    scope: AddressScope
    entries: Address[]
  },
  createOrEditAddress?: {
    errorFields?: (keyof Address)[]
    error?: string
    availableCountries?: AddressCountry[]
    countryOptions?: {
      regions: AddressRegion[]
      streetLabels: string[]
    }
  }
  shippingOptions?: ShippingOption[]
  scopeInfoFromServer?: ScopeInfoFromServer
  summary?: PurchaseSummary
  paymentButtonDisabled?: boolean,
  order?: Order
}

export const initState: ECommerceState = {
  navigation: {page: "landing"},
  formAuthToken: "",
  availableItems: [],
  busy: false,
  locale: I18n.locale,
  authorization: { access_token: "", token_type: "Bearer" },
  shop: {type: "coins"}
}
