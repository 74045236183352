import React, { useEffect, useState } from "react"
import _ from "lodash"
import { useECommerce } from "../context"
import I18n from "i18n-js"
import { ActionLinkHolder, ActionsHolder, AmountInfoView, ButtonSubinfo, ConfirmAction, Info, ItemList, ItemListSection, LoadingPlaceholder, OrderInlineLabelAndValue, OrderLabelAndValue, PageWrapper, Panel, Row, SectionTitle, SelectStickersWrapper, ShopItemView, ShortAddress, StickerToShow, Title } from "../Ui"
import Button from "@root/components/Button"
import { ApiGetOrder, ApiResp } from "../actions/serverApiActions"
import { Order, ServerErrorResp } from "../types"

export const DedicatedOrderPage: React.FC<{order?: Order, error?: ServerErrorResp}> = ({order, error}) => {
  return <PageWrapper>
    {error &&
      <div className="neo__ec__dedicatedOrder__error">
        <div>{error.error.description}</div>
      </div>}
    {order &&
      <OrderInner order={order} />}
  </PageWrapper>
}

export const OrderInner: React.FC<{order: Order}> = ({order}) => {
  const selectedStickers = _.find(order.entries, (e) => !!e.selected_stickers)?.selected_stickers

  return <>
    <Row>
      <OrderLabelAndValue
        label={I18n.t("ecommerce.order.order_number_label")}
        value={<span className="neo__ec__order__orderNumber">{order.order_number}</span>} />
    </Row>
    <Row>
      <OrderLabelAndValue
        label={I18n.t("ecommerce.order.state_label")}
        value={order.order_status} />
    </Row>
    <Row>
      <ItemList>
        {_.map(order.entries, (entry, index) => {
          return <ShopItemView hidePrice key={index} item={entry.item}>
            <AmountInfoView selectedStickers={entry.selected_stickers} hidePrice item={entry.item} amountInfo={entry.amount_info} />
          </ShopItemView>
        })}
      </ItemList>
    </Row>
    {selectedStickers && <Row>
      <Panel style="box" size="slim" fullContent>
        <SelectStickersWrapper slim>
          {_.map(selectedStickers, (s, index) => {
            return <StickerToShow key={index} sticker={s.sticker} amount={s.amount} />
          })}
        </SelectStickersWrapper>
      </Panel>
    </Row>}
    <Row>
      <ItemList>
        {order.total_cost &&
          <OrderInlineLabelAndValue
            label={I18n.t("ecommerce.order.total_cost")}
            value={order.total_cost}/>}
        <OrderInlineLabelAndValue
          label={I18n.t("ecommerce.order.payment_method")}
          value={order.payment_method} />
        {order.shipping_method &&
          <OrderInlineLabelAndValue
            label={I18n.t("ecommerce.order.shipping_method")}
            value={order.shipping_method} />}
      </ItemList>
    </Row>
    <Row>
      <ItemList>
        <ItemListSection>{I18n.t("ecommerce.order.billing_address")}</ItemListSection>
        <ShortAddress address={order.billing_address} />
        {order.shipping_address && <>
            <ItemListSection>{I18n.t("ecommerce.order.shipping_address")}</ItemListSection>
            <ShortAddress address={order.shipping_address} />
          </>}
      </ItemList>
    </Row>
  </>
}

const ViewOrder: React.FC<{orderId: number}> = ({orderId}) => {
  const {state, dispatch} = useECommerce()

  useEffect(() => {
    dispatch({type: "order__getFromServer", data: {orderId}})
  }, [orderId])

  if (!state.order) {
    return <LoadingPlaceholder />
  }

  return <PageWrapper>
    <Row>
      <Title>{I18n.t("ecommerce.order.title")}</Title>
    </Row>
    <Row>
      <Info>{I18n.t("ecommerce.order.info")}</Info>
    </Row>
    <OrderInner order={state.order} />
  </PageWrapper>
}

export default ViewOrder
