import React from "react"

import { PageTitle, SectionTitle, Block, Note } from '../../pages/sandbox'
import _ from "lodash"
import { initState } from "../state"
import { MockContextProvider } from "../context"
import { Address, ShopItemImage } from "../types"
import ViewOrder from "./ViewOrder"
import stickerImg from "@images/sandbox__sticker.jpg"

import shopYearbookImg from "@images/sandbox__shopYearbook.png"
const itemImg = (src: string): ShopItemImage => {return {large: src, thumb: src}}

const Sandbox:React.FC = () => {
  return <>
    <Block>
      <Note>Just Billing</Note>
      <MockContextProvider state={{...initState,
        order: {
          order_id: 99999999,
          order_number: "#45678900",
          order_status: "Your payment is about to be verified.",
          billing_address: {city: "Bochum", country: {name: "Germany", uid: "GER"}, firstname: "Sonic", lastname: "Hedgehog", postcode: "12345", street: ["Streeeeet", "62"], id: 99999123, telephone: "(+49) 12345678"},
          entries: [
            {
              item: {name: "Super Yearbook 2k", description: "Best of it all", price: {value: "1000,99€", subtitle: "per item"}, sku: "SANDBOX_YEARBOOK", image: itemImg(shopYearbookImg)},
              amount_info: {
                amount: 1,
                can_change: false,
                max: 10,
                total_price: {
                  value: "99€",
                  subtitle: "total"
                }
              }
            },
            {
              item: {name: "Super Yearbook 2k", description: "Best of it all", price: {value: "1000,99€", subtitle: "per item"}, sku: "SANDBOX_YEARBOOK", image: itemImg(shopYearbookImg)},
              amount_info: {
                amount: 1,
                can_change: false,
                max: 10,
                total_price: {
                  value: "99€",
                  subtitle: "total"
                }
              }
            },
          ],
          payment_method: "Card"
        }}}>
        <ViewOrder orderId={34567890}/>
      </MockContextProvider>
    </Block>
    <Block>
      <Note>Billing + Shipping + Stickers</Note>
      <MockContextProvider state={{...initState,
        order: {
          order_id: 99999999,
          order_number: "#45678900",
          order_status: "Your payment is about to be verified.",
          billing_address: {city: "Bochum", country: {name: "Germany", uid: "GER"}, firstname: "Sonic", lastname: "Hedgehog", postcode: "12345", street: ["Streeeeet", "62"], id: 99999123, telephone: "(+49) 12345678"},
          shipping_address: {city: "Bochum", country: {name: "Germany", uid: "GER"}, firstname: "Sonic", lastname: "Hedgehog", postcode: "12345", street: ["Streeeeet", "62"], id: 99999123, telephone: "(+49) 12345678"},
          shipping_method: "DHL international",
          entries: [
            {
              item: {name: "XL Stickers", description: "Best of it all", price: {value: "1000,99€", subtitle: "per item"}, sku: "SANDBOX_YEARBOOK", image: itemImg(shopYearbookImg)},
              selected_stickers: [
                {sticker: {image: stickerImg, sticker_id: 91, name: "Sticker 91", number: 91}, amount: 1},
                {sticker: {image: stickerImg, sticker_id: 92, name: "Sticker 92", number: 92}, amount: 3},
                {sticker: {image: stickerImg, sticker_id: 93, name: "Sticker 93", number: 93}, amount: 1},
                {sticker: {image: stickerImg, sticker_id: 94, name: "Sticker 94", number: 94}},
                {sticker: {image: stickerImg, sticker_id: 95, name: "Sticker 95", number: 95}, amount: 1},
              ],
              amount_info: {
                amount: 5,
                can_change: false,
                max: 10,
                total_price: {
                  value: "99€",
                  subtitle: "total"
                }
              }
            },
          ],
          payment_method: "Card"          
        }}}>
        <ViewOrder orderId={34567890}/>
      </MockContextProvider>
    </Block>
  </>
}

export default ["ec/Order", Sandbox]
