import { Authorization } from "@root/ecommerce/types"
import axios, { AxiosResponse } from "axios"
import React, { PropsWithChildren, createContext, useContext } from "react"

export const createSagaJSONPostApiContext = (accessToken: string, auth: Authorization, locale: string) => {
  return {
    post<Resp> (path: string, data?: any) { return apiPost<Resp>(path, accessToken, auth, locale, data) },
    postFormData<Resp> (path: string, formData: FormData) { return apiPostFormData(path, accessToken, formData) }
  }
}

export const JSON_POST_API_CONTEXT_KEY = "JSONPostApi"

export type JSONPostApiResp<T> = AxiosResponse<T>

export type JSONPostApiSagaContext = ReturnType<typeof createSagaJSONPostApiContext>

function apiPost<Resp = any> (path: string, authToken: string, auth: Authorization, locale: string, data?: any) {
  return axios.post<Resp>(
    path,
    {authenticity_token: authToken, ...data},
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Locale': locale,
        'Authorization': `${auth.token_type} ${auth.access_token}`
      }
    }
  )
}

function apiPostFormData<Resp = any> (path: string, authToken: string, formData: FormData) {
  formData.append("authenticity_token", authToken)

  return axios.post<Resp>(
    path,
    formData,
    {
      withCredentials: true,
      headers: {
        'Content-Type': "multipart/form-data",
      }
    }
  )
}

const JSONPostApiContext = createContext<{apiPost<Resp = any>(path: string, data?: any): Promise<AxiosResponse<Resp[], any>>}>({apiPost: () => new Promise(() => {})})

export const useApiPost = () => { return useContext(JSONPostApiContext) }

export const WithApiPost: React.FC<PropsWithChildren<{authToken: string, auth: Authorization, locale: string}>> = ({authToken, auth, locale, children}) => {
  return <JSONPostApiContext.Provider value={{
    apiPost<Req = any>(path: string, data?: any) {
      return apiPost<Req>(path, authToken, auth, locale, data)
    }
  }}>
    {children}
  </JSONPostApiContext.Provider>
}

export const WithMockApiPost: React.FC<PropsWithChildren> = ({children}) => {
  return <JSONPostApiContext.Provider value={{
    apiPost<Req = any>(path: string, data?: any) {
      console.log("Api request", path, data)
      return new Promise(() => {})
    }
  }}>
    {children}
  </JSONPostApiContext.Provider>
}
