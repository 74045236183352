import React from "react"

interface ButtonProps {
  href?: string
  onClick?(): void
  target?: string
  disabled?: boolean
  className?: string
}

const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({className: passthroughClassName, target, children: Title, href, onClick, disabled}) => {
  const className = [
    "neo__button",
    passthroughClassName || ""
  ].join(" ")

  const Inner = <span className="neo__button__inner">
    <span className="neo__button__title">{Title}</span>
  </span>
  return disabled
    ? <span className={className}>{Inner}</span>
    : <a {... {className, onClick, target, href}}>{Inner}</a>
}

export default Button
