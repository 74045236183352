import React, { useEffect } from "react"
import { useECommerce } from "../context"

const BusyOverlay: React.FC = () => {
  const {state, dispatch} = useECommerce()

  return state.busy
    ? <div className="neo__ec__busyOverlay">
        <span />
      </div>
    : undefined
}

export default BusyOverlay
