import React from "react"
import I18n from "i18n-js"
import _ from "lodash"

import SectionTitle from "@root/components/SectionTitle"
import {openLogin, openRegistration} from "../gigya"
import Button from "@root/components/Button"

import teaserSrc from "@images/completeProfile__teaser.png"

export default () => {
  return <div className="neo__completeProfile">
    <div className="neo__completeProfile__inner">
      <div className="neo__completeProfile__contentRow">
        <SectionTitle>{I18n.t("complete_profile.subtitle")}</SectionTitle>
      </div>
      <div className="neo__completeProfile__contentRow">
        <div className="neo__completeProfile__teaser">
          <img src={teaserSrc} />
        </div>
      </div>
      <div className="neo__completeProfile__contentRow">
        <div className="neo__completeProfile__rewardsHolder">
          <div className="neo__completeProfile__rewardsTitle">
            <h3>{I18n.t("complete_profile.rewards.title")}</h3>
          </div>
          <ul className="neo__completeProfile__rewards">
            <li>{I18n.t("complete_profile.rewards.participate_in_all_collections")}</li>
            <li>{I18n.t("complete_profile.rewards.free_packs")}</li>
            <li>{I18n.t("complete_profile.rewards.swap_stickers")}</li>
            <li>{I18n.t("complete_profile.rewards.order_printed_copies")}</li>
          </ul>
        </div>
        <div className="neo__completeProfile__rewardDisclaimer">{I18n.t("complete_profile.rewards.disclaimer")}</div>
      </div>
      <div className="neo__completeProfile__contentRow">
        <div className="neo__completeProfile__actions">
          <Button onClick={openRegistration}>{I18n.t("complete_profile.btn_register")}</Button>
          <a className="neo__completeProfile__loginLink" onClick={() => openLogin()}>{I18n.t("complete_profile.btn_login")}</a>
        </div>
      </div>
    </div>
  </div>
}
