import { spawn, take } from "redux-saga/effects"
import { all, put, takeEvery, getContext, select } from "redux-saga/effects"
import { ActionForType, ECommerceActionType, ECommerceActions } from "../actions"
import { JSONPostApiResp, JSONPostApiSagaContext, JSON_POST_API_CONTEXT_KEY } from "@root/misc/jsonPostApi"
import _ from "lodash"
import { ServerErrorResp, isServerError } from "../types"
import { ECommerceState } from "../state"
import {apiPath} from "../ecommerceRoutes"
import { actionTypes } from "../helper"
import I18n from "i18n-js"
import paymentSaga from "./paymentSaga"
import { ApiGetOrder, ApiReq } from "../actions/serverApiActions"

type ServerResponseEntry = {action: string} | ({action: string} & ServerErrorResp)

const handleServerResponse = function* (resp: ServerResponseEntry[]) {
  for(let i = 0, r; r = resp[i]; i++) {
    // extract scope
    if (!_.isUndefined((r as any).cart_id) || !_.isUndefined((r as any).customer_access_token)) {
      yield put<ECommerceActions>({type: "ecommerce__setScopeInfoFromServer", data: {
        customer_access_token: (r as any).customer_access_token,
        transaction_id: (r as any).transaction_id || undefined
      }})
    }

    // throw action
    yield put<ECommerceActions>({ type: `api__receive__${r.action}` as any, data: r as any})
  }
}

const addScopeInfo = function*(req: any) {
  const state: ECommerceState = yield select((s: ECommerceState) => s)
  return _.merge({}, req, state.scopeInfoFromServer)
}

const getAvailableItems = function*(action: ActionForType<"ecommerce__getAvailableItems">) {
  const state: ECommerceState = yield select((s: ECommerceState) => s)
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/get_available_items"),
    data: {shop: state.shop.type, collection: state.shop.collection}
  }})
  yield take<ECommerceActions>(actionTypes("api__request__error", "api__receive__ecommerce/get_available_items"))
  yield put<ECommerceActions>({type: "busy__setNotBusy"})
}

const getCart = function*(action: ActionForType<"ecommerce__getCart">) {
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/get_cart"),
    data: {}
  }})
  yield take<ECommerceActions>(actionTypes("api__request__error", "api__receive__ecommerce/get_cart"))
  yield put<ECommerceActions>({type: "busy__setNotBusy"})
}

const getSummary = function*(action: ActionForType<"ecommerce__getSummary">) {
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/get_summary"),
    data: {}
  }})
  yield take<ECommerceActions>(actionTypes("api__request__error", "api__receive__ecommerce/get_summary"))
  yield put<ECommerceActions>({type: "busy__setNotBusy"})
}

const getOrder = function*(action: ActionForType<"order__getFromServer">) {
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/get_order"),
    data: {
      order_id: action.data.orderId
    } as ApiReq<ApiGetOrder>
  }})
  yield take<ECommerceActions>(actionTypes("api__request__error", "api__receive__ecommerce/get_order"))
  yield put<ECommerceActions>({type: "busy__setNotBusy"})
}

const useCouponCode = function*(action: ActionForType<"ecommerce__useCouponCode">) {
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/coupon_use_code"),
    data: { code: action.data.code }
  }})
  yield take<ECommerceActions>(actionTypes("api__request__error", "api__receive__ecommerce/coupon_use_code"))
  yield put<ECommerceActions>({type: "busy__setNotBusy"})
}

const removeCouponCode = function*(action: ActionForType<"ecommerce__removeCouponCode">) {
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/coupon_remove_code"),
    data: {}
  }})
  yield take<ECommerceActions>(actionTypes("api__request__error", "api__receive__ecommerce/coupon_remove_code"))
  yield put<ECommerceActions>({type: "busy__setNotBusy"})
}

const getAddresses = function*(action: ActionForType<"ecommerce__getAddresses">) {
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/get_addresses"),
    data: {
      address_scope: action.data.addressScope
    }
  }})
  yield take<ECommerceActions>(actionTypes(
    "api__request__error",
    "api__receive__ecommerce/get_addresses")
  )
  yield put<ECommerceActions>({type: "busy__setNotBusy"})
}

const getItemConfigurationInfo = function*(action: ActionForType<"ecommerce__getItemConfigurationInfo">) {
  const state: ECommerceState = yield select((s: ECommerceState) => s)
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/item_get_configuration_info"),
    data: {
      item_sku: action.data.item.sku,
      collection: state.shop.collection
    }
  }})
  yield take<ECommerceActions>(actionTypes(
    "api__request__error",
    "api__receive__ecommerce/item_get_configuration_info")
  )
  yield put<ECommerceActions>({type: "busy__setNotBusy"})
}

const requestOptionsForCountry = function*(action: ActionForType<"ecommerce__requestOptionsForCountry">) {
  const state: ECommerceState = yield select((s: ECommerceState) => s)
  // NO GLOBAL BUSY - just fills regions
  yield put<ECommerceActions>({type: "ecommerce__clearCountryOptions"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/address_get_info_for_country"),
    data: {
      address_scope: action.data.addressScope,
      country_uid: action.data.countryUid
    }
  }})
  yield take<ECommerceActions>(actionTypes(
    "api__request__error",
    "api__receive__ecommerce/address_get_info_for_country")
  )
  // NO GLOBAL BUSY - just fills regions
}

const goToItemConfiguration = function*(action: ActionForType<"ecommerce__goToItemConfiguration">) {
  yield put<ECommerceActions>({type: "ecommerce__clearItemConfigurationInfo"})
  yield put<ECommerceActions>({type: "navigation__set", data: {navigation: {page: "selectStickers", item: action.data.item}}})
}

const addItemToCartAndShowCart = function*(action: ActionForType<"ecommerce__addItemToCartAndShowCart">) {
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/cart_add_item"),
    data: {
      item_sku: action.data.item.sku,
      configuration: action.data.configuration
    }
  }})
  const resp: ECommerceActions = yield take<ECommerceActions>(actionTypes("api__request__error", "api__receive__ecommerce/cart_add_item"))
  yield put<ECommerceActions>({type: "busy__setNotBusy"})

  if (resp.type === "api__request__error") {
  } else if (resp.type === "api__receive__ecommerce/cart_add_item") {
    if (isServerError(resp.data)) {
      yield put<ECommerceActions>({type: "displayError__set", data: {error: resp.data.error.description}})
    } else {
      yield put<ECommerceActions>({type: "ecommerce__clearCart"})
      yield put<ECommerceActions>({type: "navigation__set", data: {navigation: {page: "cart"}}})
    }
  }
}

const tryToSaveNewOrEditedAddress = function*(action: ActionForType<"ecommerce__tryToSaveNewOrEditedAddress">) {
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "ecommerce__clearCreateOrEditErrorInfo"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/address_create_or_update"),
    data: {
      address_scope: action.data.addressScope,
      address: action.data.address
    }
  }})
  const resp: ECommerceActions = yield take<ECommerceActions>(actionTypes(
    "api__request__error",
    "api__receive__ecommerce/address_create_or_update")
  )
  yield put<ECommerceActions>({type: "busy__setNotBusy"})

  if (resp.type === "api__request__error") {
  } else if (resp.type === "api__receive__ecommerce/address_create_or_update") {
    if (isServerError(resp.data)) {
      // nothing here - errors will be shown in form
    } else {
      // back to "addresses"
      yield put<ECommerceActions>({type: "ecommerce__clearCreateOrEditData"})
      yield put<ECommerceActions>({type: "navigation__set", data: {navigation: {page: "addresses", addressScope: action.data.addressScope, needsShipping: action.data.needsShipping}}})
    }
  }
}

const dontSaveNewAddressJustGoBack = function*(action: ActionForType<"ecommerce__dontSaveNewAddressJustGoBack">) {
  yield put<ECommerceActions>({type: "ecommerce__clearCreateOrEditData"})
  yield put<ECommerceActions>({type: "navigation__set", data: {navigation: {page: "addresses", addressScope: action.data.addressScope, needsShipping: action.data.needsShipping}}})
}

const toCreateOrEditAddress = function*(action: ActionForType<"ecommerce__toCreateOrEditAddress">) {
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "ecommerce__clearCreateOrEditData"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/address_get_available_countries"),
    data: {
      address_scope: action.data.addressScope
    }
  }})
  const resp: ECommerceActions = yield take<ECommerceActions>(actionTypes(
    "api__request__error",
    "api__receive__ecommerce/address_get_available_countries")
  )
  yield put<ECommerceActions>({type: "busy__setNotBusy"})

  if (resp.type === "api__request__error") {
    // nothing
  } else if (resp.type === "api__receive__ecommerce/address_get_available_countries") {
    if (isServerError(resp.data)) {
      yield put<ECommerceActions>({type: "displayError__set", data: {error: resp.data.error.description}})
    } else {
      let resp2: ECommerceActions
      if (action.data.address && action.data.address.country) {
        // editing? also directly pull country options
        yield put<ECommerceActions>({type: "busy__setBusy"})
        yield put<ECommerceActions>({type: "api__request", data: {
          action: apiPath("api__receive__ecommerce/address_get_info_for_country"),
          data: {
            address_scope: action.data.addressScope,
            country_uid: action.data.address.country.uid
          }
        }})
        resp2 = yield take<ECommerceActions>(actionTypes(
          "api__request__error",
          "api__receive__ecommerce/address_get_info_for_country")
        )
        yield put<ECommerceActions>({type: "busy__setNotBusy"})      
      }

      if (resp2 && resp2.type === "api__request__error") {
        // nothing
      } else if (resp2 && resp2.type === "api__receive__ecommerce/address_get_info_for_country" && isServerError(resp2.data)) {
        yield put<ECommerceActions>({type: "displayError__set", data: {error: resp2.data.error.description}})
      } else {
        yield put<ECommerceActions>({type: "navigation__set", data: {navigation: {page: "createOrEditAddress", addressScope: action.data.addressScope, address: action.data.address, needsShipping: action.data.needsShipping, suggestedCountryUid: resp.data.suggested_country_uid}}})
      }    
    }
  }
}

const removeItemFromCartAndGoBackToLanding = function*(action: ActionForType<"ecommerce__removeItemFromCartAndGoBackToLanding">) {
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/cart_remove_item"),
    data: {
      item_sku: action.data.item.sku
    }
  }})
  const resp: ECommerceActions = yield take<ECommerceActions>(actionTypes("api__request__error", "api__receive__ecommerce/cart_remove_item"))
  yield put<ECommerceActions>({type: "busy__setNotBusy"})

  if (resp.type === "api__request__error") {
  } else if (resp.type === "api__receive__ecommerce/cart_remove_item") {
    if (isServerError(resp.data)) {
      yield put<ECommerceActions>({type: "displayError__set", data: {error: resp.data.error.description}})
    } else {
      yield put<ECommerceActions>({type: "navigation__set", data: {navigation: {page: "landing"}}})
    }
  }
}

const changeItemAmountInCart = function*(action: ActionForType<"ecommerce__changeItemAmountInCart">) {
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/cart_change_item_amount"),
    data: {item_sku: action.data.item.sku, amount: action.data.amount}
  }})
  const resp: ECommerceActions = yield take<ECommerceActions>(actionTypes("api__request__error", "api__receive__ecommerce/cart_change_item_amount"))
  yield put<ECommerceActions>({type: "busy__setNotBusy"})

  if (resp.type === "api__request__error") {
  } else if (resp.type === "api__receive__ecommerce/cart_change_item_amount") {
    if (isServerError(resp.data)) {
      yield put<ECommerceActions>({type: "displayError__set", data: {error: resp.data.error.description}})
    }
  }
}

const selectShippingOption = function*(action: ActionForType<"ecommerce__selectShippingOption">) {
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/shipping_select"),
    data: {carrier_code: action.data.shippingOption.carrier_code, method_code: action.data.shippingOption.method_code}
  }})
  const resp: ECommerceActions = yield take<ECommerceActions>(actionTypes(
    "api__request__error",
    "api__receive__ecommerce/shipping_select")
  )
  yield put<ECommerceActions>({type: "busy__setNotBusy"})

  if (resp.type === "api__request__error") {
  } else if (resp.type === "api__receive__ecommerce/shipping_select") {
    if (isServerError(resp.data)) {
      yield put<ECommerceActions>({type: "displayError__set", data: {error: resp.data.error.description}})
    } else {
      yield put<ECommerceActions>({type: "navigation__set", data: {navigation: {page: "summary"}}})
    }
  }
}

const deleteAddress = function*(action: ActionForType<"ecommerce__deleteAddress">) {
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/address_delete"),
    data: {address_id: action.data.addressId, address_scope: action.data.addressScope}
  }})
  const resp: ECommerceActions = yield take<ECommerceActions>(actionTypes(
    "api__request__error",
    "api__receive__ecommerce/address_delete")
  )
  yield put<ECommerceActions>({type: "busy__setNotBusy"})

  if (resp.type === "api__request__error") {
  } else if (resp.type === "api__receive__ecommerce/address_delete") {
    if (isServerError(resp.data)) {
      yield put<ECommerceActions>({type: "displayError__set", data: {error: resp.data.error.description}})
    } else {
      yield put<ECommerceActions>({type: "ecommerce__clearAddresses"})
      yield put<ECommerceActions>({type: "navigation__set", data: {navigation: {page: "addresses", addressScope: action.data.addressScope, needsShipping: action.data.needsShipping}}})
    }
  }
}

const selectAddress = function*(action: ActionForType<"ecommerce__selectAddress">) {
  yield put<ECommerceActions>({type: "busy__setBusy"})
  yield put<ECommerceActions>({type: "api__request", data: {
    action: apiPath("api__receive__ecommerce/address_select"),
    data: {
      address_scope: action.data.addressScope,
      address_id: action.data.addressId
    }
  }})
  const resp: ECommerceActions = yield take<ECommerceActions>(actionTypes(
    "api__request__error",
    "api__receive__ecommerce/address_select"
  ))
  yield put<ECommerceActions>({type: "busy__setNotBusy"})

  if (resp.type === "api__request__error") {
  } else if (resp.type === "api__receive__ecommerce/address_select") {
    if (isServerError(resp.data)) {
      yield put<ECommerceActions>({type: "displayError__set", data: {error: resp.data.error.description}})
    } else {
      if (action.data.addressScope === "billing") {
        if (action.data.needsShipping) {
          // to shipping
          yield put<ECommerceActions>({type: "ecommerce__clearAddresses"})
          yield put<ECommerceActions>({type: "navigation__set", data: {navigation: {page: "addresses", addressScope: "shipping", needsShipping: true}}})
        } else {
          // to summary
          yield put<ECommerceActions>({type: "ecommerce__clearSummary"})
          yield put<ECommerceActions>({type: "navigation__set", data: {navigation: {page: "summary"}}})
        }
      } else if (action.data.addressScope === "shipping") {
        // to select shipping option
        if (resp.data.shipping_options && resp.data.shipping_options.length > 0) {
          yield put<ECommerceActions>({type: "navigation__set", data: {navigation: {page: "selectShipping"}}})
        } else {
          yield put<ECommerceActions>({type: "displayError__set", data: {error: I18n.t("ecommerce.address.form.error_no_shipping_options")}})
        }
      }
    }
  }
}

const toBillingAddress = function*(action: ActionForType<"ecommerce__toBillingAddress">) {
  yield put<ECommerceActions>({type: "ecommerce__clearAddresses"})  
  yield put<ECommerceActions>({type: "navigation__set", data: {navigation: {page: "addresses", addressScope: "billing", needsShipping: action.data.needsShipping}}})
}

const navigationScrollToTop = function*(action: ActionForType<"navigation__scrollToTop">) {
  window.scrollTo({top: 0})
}

const apiRequest = function*(action: ActionForType<"api__request">) {
  const api: JSONPostApiSagaContext = yield getContext(JSON_POST_API_CONTEXT_KEY)
  try {
    const postData: Object = yield addScopeInfo(action.data.data) as any
    const { data }: JSONPostApiResp<any> = yield api.post(
      action.data.action, postData
    )
    yield handleServerResponse(data)
  } catch(e) {
    yield put<ECommerceActions>({type: "api__request__error", data: {...action.data, error: e}})
  }
}

export function* ecommerceSaga() {
  yield all([
    takeEvery<ECommerceActionType>("ecommerce__getAvailableItems", getAvailableItems),
    takeEvery<ECommerceActionType>("ecommerce__getCart", getCart),
    takeEvery<ECommerceActionType>("order__getFromServer", getOrder),
    takeEvery<ECommerceActionType>("ecommerce__getSummary", getSummary),
    takeEvery<ECommerceActionType>("ecommerce__useCouponCode", useCouponCode),
    takeEvery<ECommerceActionType>("ecommerce__removeCouponCode", removeCouponCode),
    takeEvery<ECommerceActionType>("ecommerce__getAddresses", getAddresses),
    takeEvery<ECommerceActionType>("ecommerce__getItemConfigurationInfo", getItemConfigurationInfo),
    takeEvery<ECommerceActionType>("ecommerce__requestOptionsForCountry", requestOptionsForCountry),
    takeEvery<ECommerceActionType>("ecommerce__addItemToCartAndShowCart", addItemToCartAndShowCart),
    takeEvery<ECommerceActionType>("ecommerce__toCreateOrEditAddress", toCreateOrEditAddress),
    takeEvery<ECommerceActionType>("ecommerce__goToItemConfiguration", goToItemConfiguration),
    takeEvery<ECommerceActionType>("ecommerce__tryToSaveNewOrEditedAddress", tryToSaveNewOrEditedAddress),
    takeEvery<ECommerceActionType>("ecommerce__dontSaveNewAddressJustGoBack", dontSaveNewAddressJustGoBack),
    takeEvery<ECommerceActionType>("ecommerce__removeItemFromCartAndGoBackToLanding", removeItemFromCartAndGoBackToLanding),
    takeEvery<ECommerceActionType>("ecommerce__changeItemAmountInCart", changeItemAmountInCart),
    takeEvery<ECommerceActionType>("ecommerce__selectAddress", selectAddress),
    takeEvery<ECommerceActionType>("ecommerce__selectShippingOption", selectShippingOption),
    takeEvery<ECommerceActionType>("ecommerce__toBillingAddress", toBillingAddress),
    takeEvery<ECommerceActionType>("ecommerce__deleteAddress", deleteAddress),
    takeEvery<ECommerceActionType>("api__request", apiRequest),
    takeEvery<ECommerceActionType>("navigation__scrollToTop", navigationScrollToTop),
  ])
}

export default function* mainSaga() {
  yield spawn(ecommerceSaga)
  yield spawn(paymentSaga)
}
