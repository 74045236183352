import React, { useState } from "react"
import I18n from "i18n-js"

import SectionTitle from "@root/components/SectionTitle"
import _ from "lodash"
import Button from "@root/components/Button"
import { AppLinks } from "@root/types"
import iosStoreImg from "@images/download__ios.png"
import androidStoreImg from "@images/download__android.png"
import EnterCodesForIOSButton from "@root/components/EnterCodesForIOSButton"

const __browserImgContext = require.context("@images", true, /browser__.*(png)$/)
const browserImgSrc = (browser: string) => {
  return __browserImgContext(`./browser__${browser}.png`)
}


const b = (key: string) => `web.pages.browser_not_supported.${key}`
const BROWSERS = ["firefox", "chrome", "edge"]

interface CompInit {
  applinks: AppLinks
}

const BrowserNotSupported: React.FC<{init: CompInit}> = ({init}) => {
  const [browsers] = useState(_.shuffle(BROWSERS))

  return <div className="neo__browserNotSupported">
    <div className="neo__browserNotSupported__inner">

      <div className="neo__browserNotSupported__contentRow">
        <SectionTitle>{I18n.t(b("title"))}</SectionTitle>
      </div>

      <div className="neo__browserNotSupported__contentRow">
        <div className="neo__browserNotSupported__panel">
          <div className="neo__browserNotSupported__info">{I18n.t(b("info"))}</div>
          <div className="neo__browserNotSupported__browsers">
            {_.map(browsers, (browser) => {
              return <a className="neo__browserNotSupported__browser" target="_blank" href={I18n.t(b(`browser_${browser}.url`))} key={browser}>
                <img src={browserImgSrc(browser)} />
                <h3>{I18n.t(b(`browser_${browser}.title`))}</h3>
                <span>{I18n.t(b(`browser_${browser}.subtitle`))}</span>
              </a>
            })}
          </div>
          <div className="neo__browserNotSupported__appLinks">
            <a href={init.applinks.ios}><img src={iosStoreImg} /></a>
            <a href={init.applinks.android}><img src={androidStoreImg} /></a>
          </div>
        </div>
      </div>

      <div className="neo__browserNotSupported__contentRow">
        <SectionTitle>{I18n.t(b("enter_codes_info.title"))}</SectionTitle>
      </div>

      <div className="neo__browserNotSupported__contentRow">
        <div className="neo__browserNotSupported__panel">
          <div className="neo__browserNotSupported__enterCodeInfo">
            <div className="neo__browserNotSupported__enterCodeInfo__info">
              {I18n.t(b("enter_codes_info.info"))}
            </div>
            <div className="neo__browserNotSupported__enterCodeInfo__actionHolder">
              <EnterCodesForIOSButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default BrowserNotSupported
