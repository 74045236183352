import React, { useEffect, useState } from "react"
import _ from "lodash"
import { useECommerce } from "../context"
import I18n from "i18n-js"
import { SelectStickersItem, ShopItem, StickerId } from "../types"
import { ActionsHolder, ConfirmAction, Info, ItemList, LoadingPlaceholder, PageWrapper, Panel, RestrictedToCountries, Row, SelectStickersWrapper, StickerToSelect, Title } from "../Ui"
import Button from "@root/components/Button"
import { useIntersectionObserver } from "react-intersection-observer-hook"

export type SelectStickersMode = "ALLOW_MULTIPLE" | "EACH_STICKER_ONLY_ONCE"

const DEFAULT_MODE: SelectStickersMode = "EACH_STICKER_ONLY_ONCE"

const ViewSelectStickers: React.FC<{item: ShopItem, mode?: SelectStickersMode}> = ({item, mode}) => {
  mode || (mode = "EACH_STICKER_ONLY_ONCE")
  const {state, dispatch} = useECommerce()
  const [pickedStickers, setPickedStickers] = useState<{[sticker_id: number]: number}>({})
  const [showFloatingContinueButton, setShowFloatingContineButton] = useState(false)

  console.log(pickedStickers)

  const [continueButtonRef, { entry }] = useIntersectionObserver();
  useEffect(() => {
    setShowFloatingContineButton(!entry?.isIntersecting)
  }, [entry && entry.isIntersecting])

  useEffect(() => {
    dispatch({type: "ecommerce__getItemConfigurationInfo", data: {item}})
  }, [])

  if (!state.itemConfigurationInfo || !state.itemConfigurationInfo.select_stickers) {
    return <LoadingPlaceholder />
  }

  const incSticker = (id: StickerId) => {
    setPickedStickers((prev) => {
      const updated = _.clone(prev)
      updated[id] = (updated[id] || 0) + 1
      return updated
    })
  }

  const decSticker = (id: StickerId) => {
    setPickedStickers((prev) => {
      const updated = _.clone(prev)
      updated[id] = Math.max((updated[id] || 0) - 1, 0)
      return updated
    })
  }

  const numPickedStickers = _.reduce(pickedStickers, (res, val) => {
    return res + (val || 0)
  }, 0)

  const selectAndContinue = () => {
    dispatch({type: "ecommerce__addItemToCartAndShowCart", data: {
      item,
      configuration: {
        selected_stickers: _.filter(_.map(_.keys(pickedStickers), (stickerId) => {
          return {sticker_id: parseInt(stickerId), amount: pickedStickers[parseInt(stickerId)]}
        }), (entry) => entry.amount !== 0)
      }
    }})
  }

  const buttonLabel: string = I18n.t("ecommerce.select_stickers.action_continue", {count: numPickedStickers})

  return <PageWrapper>
    <Row><Title>{item.name || item.description}</Title></Row>
    <Row><Info>{I18n.t("ecommerce.select_stickers.info", {collection: state.itemConfigurationInfo.select_stickers.collection_name})}</Info></Row>
    <Row>
      <Panel size="full" fullContent style="box">
        <SelectStickersWrapper>
          {_.map(state.itemConfigurationInfo.select_stickers.stickers, (sticker) => {
            const amountSelfSelected = pickedStickers[sticker.sticker_id] || 0
            return <StickerToSelect
              mode={mode}
              key={sticker.sticker_id}
              sticker={sticker}
              blockedForSelection={false}
              amountAlreadySelected={amountSelfSelected}
              doInc={() => incSticker(sticker.sticker_id)}
              doDec={() => decSticker(sticker.sticker_id)} />
          })}
        </SelectStickersWrapper>
      </Panel>
    </Row>
    {showFloatingContinueButton && numPickedStickers > 0 &&
      <div className="neo__ec__floatingContinueButton">
        <Button disabled={numPickedStickers === 0} onClick={selectAndContinue}>{buttonLabel}</Button>
      </div>}
    <Row>
      <div ref={continueButtonRef}>
        <ActionsHolder>
          <Button disabled={numPickedStickers === 0} onClick={selectAndContinue}>{buttonLabel}</Button>
          <ConfirmAction
            actionLabel={I18n.t("ecommerce.select_stickers.action_abort")}
            action={() => dispatch({type: "navigation__set", data: {navigation: {page: "landing"}}})}
            confirmQuestion={I18n.t("ecommerce.select_stickers.confirm_abort")}
            confirmYes={I18n.t("ecommerce.select_stickers.confirm_abort_yes")}
            confirmNo={I18n.t("ecommerce.select_stickers.confirm_abort_no")} />
        </ActionsHolder>
      </div>
    </Row>
    {state.itemConfigurationInfo.restricted_to_countries &&
      <Row>
        <RestrictedToCountries countries={state.itemConfigurationInfo.restricted_to_countries} />
      </Row>}
  </PageWrapper>
}

export default ViewSelectStickers
