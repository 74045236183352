import React from "react"

import { PageTitle, SectionTitle, Block, Note } from '../../pages/sandbox'
import _ from "lodash"
import { initState } from "../state"
import { MockContextProvider } from "../context"
import shopStickersImg from "@images/sandbox__shopStickers.png"
import stickerImg from "@images/sandbox__sticker.jpg"
import { ShopItemImage } from "../types"
import ViewSelectStickers from "./ViewSelectStickers"

const itemImg = (src: string): ShopItemImage => {return {large: src, thumb: src}}

const Sandbox:React.FC = () => {
  return <>
    <Block>
    <SectionTitle>Allow multiple stickers</SectionTitle>
    <MockContextProvider state={{...initState, itemConfigurationInfo: {
        select_stickers: {
          collection_name: "Supercollection",
          stickers: [
            {image: stickerImg, sticker_id: 91, name: "Sticker 91", number: 91},
            {image: stickerImg, sticker_id: 92, name: "Sticker 92", number: 92},
            {image: stickerImg, sticker_id: 93, name: "Sticker with multiline name 93", number: 93},
            {image: stickerImg, sticker_id: 94, name: "Sticker 94", number: 94},
            {image: stickerImg, sticker_id: 95, name: "Sticker 95", number: 95},
            {image: stickerImg, sticker_id: 96, name: "Sticker 96", number: 96},
            {image: stickerImg, sticker_id: 97, name: "Sticker 97", number: 97},
          ]
        }
      }}}>
        <ViewSelectStickers mode="ALLOW_MULTIPLE" item={{name: "XL Stickers", description: "Should dispatch navigation to sticker selection!", price: {value: "1,99€", subtitle: "per item"}, sku: "SANDBOX_STICKER", needs_configuration: true, configuration_type: "select_stickers", image: itemImg(shopStickersImg)}}/>
      </MockContextProvider>
    </Block>
    <Block>
    <SectionTitle>Each sticker only once</SectionTitle>
    <MockContextProvider state={{...initState, itemConfigurationInfo: {
        select_stickers: {
          collection_name: "Supercollection",
          stickers: [
            {image: stickerImg, sticker_id: 91, name: "Sticker 91", number: 91},
            {image: stickerImg, sticker_id: 92, name: "Sticker 92", number: 92},
            {image: stickerImg, sticker_id: 93, name: "Sticker with multiline name 93", number: 93},
            {image: stickerImg, sticker_id: 94, name: "Sticker 94", number: 94},
            {image: stickerImg, sticker_id: 95, name: "Sticker 95", number: 95},
            {image: stickerImg, sticker_id: 96, name: "Sticker 96", number: 96},
            {image: stickerImg, sticker_id: 97, name: "Sticker 97", number: 97},
          ]
        }
      }}}>
        <ViewSelectStickers mode="EACH_STICKER_ONLY_ONCE" item={{name: "XL Stickers", description: "Should dispatch navigation to sticker selection!", price: {value: "1,99€", subtitle: "per item"}, sku: "SANDBOX_STICKER", needs_configuration: true, configuration_type: "select_stickers", image: itemImg(shopStickersImg)}}/>
      </MockContextProvider>
    </Block>
  </>
}

export default ["ec/SelectStickers", Sandbox]
