import ECommerce from "@root/ecommerce/ECommerce"
import { ECommerceState, initState } from "@root/ecommerce/state"
import { Authorization } from "@root/ecommerce/types"
import React, { useContext, useState } from "react"

export interface CompInit {
  form_authenticity_token: string
  authorization: Authorization
  locale: string
  shop: string
  collection: number
}

export const ECommerceMount: React.FC<{init: CompInit}> = ({init: initFromController}) => {
  const state: ECommerceState = {...initState}
  state.formAuthToken = initFromController.form_authenticity_token
  state.authorization = initFromController.authorization
  state.locale = initFromController.locale
  state.shop = {
    type: initFromController.shop as any,
    collection: initFromController.collection || undefined
  }

  return <ECommerce init={state} />
}

export default ECommerceMount
