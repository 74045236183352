import { useRef } from "react"
import _ from "lodash"

const _iosQuirkPresent = () => {
  var audio = new Audio();

  audio.volume = 0.5;
  return audio.volume === 1;   // volume cannot be changed from "1" on iOS 12 and below
}

export const isIOS = (() => {
  let initialized = false
  let _isIOS = false
  return () => {
    if (initialized) { return _isIOS }

    var navigatorCheck = /iPad|iPhone|iPod/.test(navigator.userAgent);
    var isAppleDevice = navigator.userAgent.includes('Macintosh');
    var isTouchScreen = navigator.maxTouchPoints >= 1;   // true for iOS 13 (and hopefully beyond)

    _isIOS =  navigatorCheck || (isAppleDevice && (isTouchScreen || _iosQuirkPresent()))

    initialized = true
    return _isIOS
  }
})()

export const isAndroid = (() => {
  let initialized = false
  let _isAndroid = false
  return () => {
    if (initialized) { return _isAndroid }

    _isAndroid =  /(Android)/i.test(navigator.userAgent)

    initialized = true
    return _isAndroid
  }
})()

const isMobile = () => {
  return isAndroid() || isIOS()
}

export const useIsMobile = () => {
  const _isMobile = useRef<{ios: Boolean, android: Boolean}>()
  if (!_isMobile.current) { _isMobile.current = {ios: isIOS(), android: isAndroid()} }
  return {
    isMobile: _isMobile.current.ios || _isMobile.current.android,
    isIOS: _isMobile.current.ios,
    isAndroid: _isMobile.current.android
  }
}

export default isMobile
