import {postForm, getRecursive} from "../utils"

declare global {
  var onGigyaServiceReady: Function
  var gigya: Gigya.GigyaInstance
}

const SCREENS = {
  login: {
    screenSet: "Store-RegistrationLogin",
    startScreen: "gigya-login-screen",
  },
  register: {
    screenSet: "Store-RegistrationLogin",
    startScreen: "gigya-register-screen",
  }
}

let overwrittenEventHandlers: Gigya.EventHandlers = {}

let isReady = false
const replayList: Function[] = []
let onLoginRedirectTo: string = undefined

function bindHandlers() {
  console.log("Gigya", "addEventHandlers")
  gigya.accounts.addEventHandlers({
    onLogin: onGigyaLogin,
    onLogout: onGigyaLogout,
  })
}

function onGigyaLogin(event: Gigya.OnLoginEvent) {
  console.log("Gigya", "onGigyaLogin", event);

  if (overwrittenEventHandlers.onLogin) {
    console.log("Gigya", "onGigyaLogin", "invoke overwritten handler");
    overwrittenEventHandlers.onLogin(event)
    return;
  }

  const data: Record<string, string> = {
    uid: event.UID,
    uid_signature: event.UIDSignature,
    signature_timestamp: event.signatureTimestamp
  }
  if (onLoginRedirectTo) {
    data["redirect_to"] = onLoginRedirectTo;
  }
  postForm("/session/gigya", data)
}

function onGigyaLogout(resp: any) {
  console.log("Gigya", "onGigyaLogout");

  if (overwrittenEventHandlers.onLogout) {
    console.log("Gigya", "onGigyaLogout", "invoke overwritten handler");
    overwrittenEventHandlers.onLogout(resp)
    return;
  }

  location.replace("session/logout");
}

function doWhenReady(func: Function) {
  if (isReady) {
    func()
  } else {
    replayList.push(func)
  }
}

export function setup() {
  // we might have some other code relying on the global callback
  // so we need to alias it, and call it when needed.
  const existingCallback = window.onGigyaServiceReady
  window.onGigyaServiceReady = function() {
    if(existingCallback) {
      existingCallback()
    }
    console.log("Gigya", "is ready:", window.gigya)
    bindHandlers()
    isReady = true
    // now replay if needed
    while(replayList.length) {
      const func = replayList.pop()
      func()
    }
  }
}

export function overwriteEventHandlers(handlers: Gigya.EventHandlers) {
  overwrittenEventHandlers = handlers;
}

export function openLogin(redirectTo?: "enterCode") {
  onLoginRedirectTo = redirectTo
  doWhenReady(() => {
    console.log("Gigya", "show", SCREENS.login)
    gigya.accounts.showScreenSet(SCREENS.login)
  })
}

export function openRegistration() {
  doWhenReady(() => {
    console.log("Gigya", "show", SCREENS.register)
    gigya.accounts.showScreenSet(SCREENS.register)
  })
}

export function logout() {
  doWhenReady(() => {
    console.log("Gigya", "logout")
    gigya.accounts.logout()
  })
}

export function request(method: string, parameters: object) {
  console.log("Gigya", "request:", method, parameters)
  const func = getRecursive(gigya, method)
  if(typeof func === "function") {
    func(parameters)
  }
}

export function showPlugin(parameters: Gigya.ScreenSetParameters) {
  console.log("Gigya", "showPlugin:", parameters)
  gigya.accounts.showScreenSet(parameters)
}

export function caseByLogin({loggedIn, notLoggedIn}: {loggedIn: Function, notLoggedIn: Function}) {
  request("accounts.getAccountInfo", {
    callback: function (response: any) {
      console.log("Gigya", "account info:", response)
      if (response.errorCode === 0 && response.UID) {
        loggedIn()
      } else {
        notLoggedIn()
      }
    },
  })
}
