import React from "react"

import { PageTitle, SectionTitle, Block, Note } from './sandbox'
import _ from "lodash"
import { LandingActionArea, LandingContext } from "./landing"
import { AppLinks } from "@root/types"

const Sandbox:React.FC = () => {
  const appLinks: AppLinks = {
    android: "android-url",
    ios: "ios-url"
  }
  return <>
    <PageTitle>Landing</PageTitle>

    <SectionTitle>Action Area</SectionTitle>
    <Note>Desktop</Note>
    <Block>
      <LandingContext.Provider value={{mode: "desktop"}}>
        <LandingActionArea appLinks={appLinks} />
      </LandingContext.Provider>
    </Block>
    <Note>iOS</Note>
    <Block>
      <LandingContext.Provider value={{mode: "ios"}}>
        <LandingActionArea appLinks={appLinks} />
      </LandingContext.Provider>
    </Block>
    <Note>Android</Note>
    <Block>
      <LandingContext.Provider value={{mode: "android"}}>
        <LandingActionArea appLinks={appLinks} />
      </LandingContext.Provider>
    </Block>

    <SectionTitle>Action Area when COMING SOON</SectionTitle>
    <Note>Desktop</Note>
    <Block>
      <LandingContext.Provider value={{comingSoon: true, mode: "desktop"}}>
        <LandingActionArea appLinks={appLinks} />
      </LandingContext.Provider>
    </Block>
    <Note>iOS</Note>
    <Block>
      <LandingContext.Provider value={{comingSoon: true, mode: "ios"}}>
        <LandingActionArea appLinks={appLinks} />
      </LandingContext.Provider>
    </Block>
    <Note>Android</Note>
    <Block>
      <LandingContext.Provider value={{comingSoon: true, mode: "android"}}>
        <LandingActionArea appLinks={appLinks} />
      </LandingContext.Provider>
    </Block>
  </>
}

export default Sandbox
