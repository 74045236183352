import Rails from "@rails/ujs"

export function postForm(url: string, params?: Record<string, string>) {
  const data = {
    ...(params || {}),
    [Rails.csrfParam()]: Rails.csrfToken(),
    _method: "POST"
  }
  var form = document.createElement("form")

  Object.keys(data).forEach(key => {
    const input = document.createElement("input")
    input.setAttribute("name", key)
    input.setAttribute("type", "hidden")
    input.setAttribute("value", data[key])
    form.appendChild(input)
  })

  // Must trigger submit by click on a button, else "submit" event handler won't work!
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/submit
  const inputSubmit = document.createElement("input")
  inputSubmit.setAttribute("type", "submit")
  form.appendChild(inputSubmit)

  form.method = "post"
  form.action = url
  form.style.display = "none"

  document.body.appendChild(form)
  inputSubmit.click()
}

export function getRecursive(obj: any, path: string | string[], defValue: any = undefined): any {
  if (!path) return undefined

  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)

  const result = pathArray.reduce(
    (prevObj, key) => prevObj && prevObj[key as any],
    obj
  )

  return result === undefined ? defValue : result
}
