import React from "react"

import Landing from "./landing"
import { AppLinks } from "@root/types"

interface CompInit {
  applinks: AppLinks
}

const ComingSoon: React.FC<{init: CompInit}> = ({init}) => {
  return <Landing comingSoon init={init}/>
}

export default ComingSoon
